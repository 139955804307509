import { capitalize, flatMap, get, intersection, isFunction, isObject, isString, union, uniq, uniqBy } from "lodash";
import { AggTypes, BaseQuantityType, CustomKpi, ProductCaseAggregationStatistics, ProductDeviationStatisticsSchema, StatsCalculationRequest } from "./ApiTypes";
import { AggregationTypes, KpiComparisons } from "../contexts/ContextTypes";
import { SessionType, isOniqEmployee } from "../contexts/SessionContext";
import { SettingsType } from "../contexts/SettingsContext";
import i18n, { getFirstExistingSpotlight } from "../i18n";
import { Formatter, UnitMetadata } from "../utils/Formatter";
import { QuantityType, getAssignedQuantities } from "../utils/Quantities";
import { capitalizeFirst } from "../utils/Utils";
import { CaseAggregationStatistics } from "./Case";
import { GroupingKeys, groupingKeysNoneGroups, groupingKeysPassCompatibleGroups } from "./Dfg";
import { EventKeys } from "./EventKeys";
import { KpiTypes, StatisticTypes } from "./KpiTypes";
import { Stats } from "./Stats";
import stringify from "json-stable-stringify";
import { TimeperiodCaseAggregationStatisticsSchema, TimeperiodCaseStatisticsSchema } from "./generated";
import { ProductCaseAggregationStatisticsSchema } from "./generated";
import { getPlanningState } from "../utils/SettingsUtils";
import { EventStatisticsTypes } from "./Project";
import { buildAttributeFilter } from "../utils/FilterBuilder";

export type TimeTypes = "setup" | "busy" | "production" | "failure" | "interruption" | "technicalLosses" | "organizationalLosses" | "processLosses" | "qualityLosses";

export enum DataGapFilling {
    /**
     * Missing values are skipped and the line graph is not connected.
     */
    Skip,

    /**
     * Missing values are replaced with 0.
     */
    Zero,

    /**
     * Line graph just connects the data points, even if there are gaps in between.
     */
    Default,
}

const statsWithoutSum = [StatisticTypes.Median, StatisticTypes.Mean, StatisticTypes.Variance];
const allStats = [...statsWithoutSum, StatisticTypes.Sum];

const noPlanningForRoutings = (session: SessionType) => {
    const { hasRoutings } = getPlanningState(session);
    if (hasRoutings)
        return [KpiComparisons.None, KpiComparisons.BestProcesses];
    return [KpiComparisons.None, KpiComparisons.Planning, KpiComparisons.BestProcesses];
};


export enum TimeperiodApis {
    /**
     * If a KPI definition has timeseriesApi===CaseDeviation, then the deviation API **MUST** be used.
     * It is assumed, that the KPI wouldn't make sense without it.
     */
    CaseDeviation = "deviation",
    Event = "event",
    Case = "case",
    Equipment = "equipment",
}

type PathDefinitions = {
    /**
     * Path to the mean value.
     */
    mean?: string,
    /**
     * Path to the median value.
     */
    median?: string,
    /**
     * Path to the statistics that need to contain median, p25 and p75 values.
     */
    variance?: string,
    /**
     * Path to the sum value.
     */
    sum?: string,
};

export type AllowedKpiQuantities = {
    /**
     * The quantities that are allowed for non-deviation endpoints.
     */
    actual: {
        node: BaseQuantityType[];
        case: BaseQuantityType[];
        timeperiod?: BaseQuantityType[];
    },

    /**
     * The quantities that are allowed for deviation endpoints
     */
    plan: {
        node: BaseQuantityType[];
        case: BaseQuantityType[];
    }
}

const noAllowedKpiQuantities: AllowedKpiQuantities = {
    actual: {
        node: [],
        case: [],
    },
    plan: {
        node: [],
        case: [],
    }
};

export type KpiDefinition = {
    timeperiodApi?: TimeperiodApis,

    /**
     * Defines how gaps in time series data should be treated. Defaults to skip. Currently only used for line charts
     */
    dataGapFilling?: DataGapFilling,

    id: KpiTypes,

    /**
     * true if this KPI requires a quantity selection
     */
    isQuantityDependent: boolean,

    /**
     * Allowed quantities that this KPI works with.
     * This list depends on the statistics used, so if that is subject to change just
     * call getKpiDefiniton again to get an updated list!
     */
    allowedQuantities: AllowedKpiQuantities;

    /**
     * Spotlight ID that explains this KPI.
     */
    spotlightId: string;

    /**
     * The label of the kpi that is translated via i18n.
     */
    label: string,

    /**
     * The label for the planned KPI.
     */
    labelPlan?: string,

    /**
     * Unit that is used to display the values.
     */
    unit: UnitMetadata | { sum: UnitMetadata, mean: UnitMetadata },

    /**
     * The allowed statistics for the kpi.
     * These are used for the initialization and for the controller.
     */
    allowedStatistics: StatisticTypes[],

    /**
     * Whether the kpi is better if it is lower or higher.
     * It is used to determined whether top20 or bottom20 should be displayed as best case comparison.
     */
    isLessBetter: boolean | undefined,

    /**
     * It is used to determined if the kpi is an equipment stats kpi.
     */
    isEquipmentStatsKpi?: boolean | undefined,

    /**
     * It is used to determined if the kpi should include WIP.
     */
    isWipIncluded?: boolean | undefined;

    /**
    * The kpi is always calculated based on pass deviations.
    * We always only show the number in the schedule deviation tab and no deviation values.
    */
    isPassDeviation?: boolean | undefined;

    /**
     * In case some event keys are needed for the kpi to make sense they should be listed here.
     */
    requiredEventKeys?: string[],

    /**
     * Whether the kpi is only available for planning data.
     */
    requiresPlanningData?: boolean,

    /**
     * Parameters that should be supplied to the api such as calculateProductionStats or calculateOutputStats.
     */
    apiParameters?: StatsCalculationRequest,

    /**
     * To specify the indentation level when the kpi should be indented in the dropdown.
     * We have different levels first level for example the busy subtime components (production, unit delay time etc.)
     * and we have the second level for example unit delay time components (technicalLosses, organizationalLosses, etc.)
     */
    indentationLevel?: number,

    /**
     * Comparisons that are allowed for the KPI.
     * You will probably want to use getDefaultEnabledKpis to narrow these comparisons down to
     * what the project actually supports, given the selected statistics.
     */
    allowedComparisons: KpiComparisons[],

    /**
     * The path to the statistics for the product and time aggregation.
     * It can either be a single string pointing to the statistic that contains all information (mean, median, p25, p75, sum)
     * or it can be an object with the keys mean, median, variance and sum.
     *
     * SettingsUtils.getProductPathFromDefinition can be used to get the correct path so you don't
     * have to deal with all this.
     */
    productStatisticsPath?: string | PathDefinitions,

    /**
     * The path to the statistics for the case aggregation.
     */
    caseStatisticsPath?: string,

    /**
     * The path to the statistics for the log aggregation.
     */
    logStatisticsPath?: string | PathDefinitions,

    /**
     * List of custom kpis that are needed for the product and time aggregation.
     * You don't need to touch this by yourself.
     */
    productCustomKpis?: CustomKpi[],

    /**
     * List of custom kpis that are needed in the case aggregation.
     */
    caseCustomKpis?: CustomKpi[],

    eventOverTimeCustomKpis?: CustomKpi[],

    /**
     * The path to the statistics for the node.
     * It can either be a single string pointing to the statistic that contains all information (mean, median, p25, p75, sum)
     * or it can be an object with the keys mean, median, variance and sum.
     */
    nodeStatisticsPath?: string | PathDefinitions,

    /**
     * The path to the equipment statistics for the node.
     */
    equipmentNodeStatsPath?: string | PathDefinitions,

    /**
     * List of custom kpis that are needed for the nodes.
     * You don't need to touch this by yourself.
     */
    nodeCustomKpis?: CustomKpi[],

    /**
     * List of custom kpis that are needed for the edges.
     */
    edgeCustomKpis?: CustomKpi[],

    /**
     * List of custom kpis that are needed for the equipment statistics.
     */
    equipmentNodeCustomKpis?: CustomKpi[],

    /**
     * The edge statistics path that is used.
     */
    edgeStatisticsPath?: string | PathDefinitions,

    /**
     * The path for the edge statistics for the timeperiod aggregation.
     */
    edgeOverTimeCustomKpis?: CustomKpi[],

    /**
     * The path for node statistics for the timeperiod aggregation.
     */
    nodeOverTimeCustomKpis?: CustomKpi[],

    /**
     * The path for edge statistics for the timeperiod aggregation.
     */
    edgeOverTimeStatisticsPath?: string | PathDefinitions,

    /**
     * The path for equipment statistics for the timeperiod aggregation.
     */
    equipmentOverTimeStatisticsPath?: string | PathDefinitions,

    /**
     * The path for the node statistics for the timeperiod aggregation.
     */
    nodeOverTimeStatisticsPath?: string | PathDefinitions,

    eventOverTimeStatisticsPath?: string | PathDefinitions,
}

// Sometimes, the paths may rely on the settings. In that case, we're using
// this alternative KPI definition here. However, this is not exported, so
// and only used as an interface with getKpiDefinition.
type KpiDefinitionWithFunctions = Omit<KpiDefinition,
    "unit" | "id" | "allowedQuantities" | "allowedComparisons" | "requiredEventKeys" |
    "nodeStatisticsPath" | "equipmentNodeStatsPath" | "eventOverTimeStatisticsPath" | "edgeStatisticsPath" | "productStatisticsPath" | "caseStatisticsPath" | "logStatisticsPath" |
    "productCustomKpis" | "caseCustomKpis" | "eventOverTimeCustomKpis" | "nodeCustomKpis" | "edgeCustomKpis" | "equipmentNodeCustomKpis" | "edgeOverTimeStatisticsPath" | "edgeOverTimeCustomKpis" | "nodeOverTimeStatisticsPath" | "nodeOverTimeCustomKpis" | "equipmentOverTimeStatisticsPath" |
    "isWipIncluded"> & {
        /**
         * Some KPIs can only be obtained via the deviation API. If timeperiodApi === TimeperiodApis.CaseDeviation,
         * the deviation API must be used.
         */
        timeperiodApi?: TimeperiodApis;

        /**
         * Defines how gaps in time series data should be treated. Defaults to skip. Currently only used for line charts
         */
        dataGapFilling?: DataGapFilling;

        /**
         * The unit of the kpi. This can be a function that takes the settings as an argument.
         */
        unit: UnitMetadata | { sum: UnitMetadata, mean: UnitMetadata } | ((settings: SettingsType) => UnitMetadata | { sum: UnitMetadata, mean: UnitMetadata }),

        /**
         * The path to the statistics for the product and time aggregation.
         * This can be a function that takes the settings as an argument.
         */
        requiredEventKeys?: string[] | ((session: SessionType, settings: SettingsType) => string[]),

        /**
         * to determined if the kpi should include WIP.
         * This can be a function that takes the sessions as an argument.
         */
        isWipIncluded?: boolean | ((session: SessionType) => boolean | undefined);

        /**
         * The path to the statistics for the product and time aggregation.
         * This can be a function that takes the settings as an argument.
         * It can either be a single string or an object containing
         * that resolves statistics to the corresponding path (mean, median, p25, p75, sum).
         */
        productStatisticsPath?: string | PathDefinitions | ((settings: SettingsType, session?: SessionType) => string | PathDefinitions | undefined),

        /**
         * The path to the statistics for the log aggregation.
         * In case it is not supplied, the product path is used.
         */
        logStatisticsPath?: string | PathDefinitions | ((settings: SettingsType) => string | PathDefinitions | undefined),

        allowedComparisons?: KpiComparisons[] | ((session: SessionType, settings: SettingsType) => KpiComparisons[]),

        /**
         * This list depends on the statistics used, so if that is subject to change just
         * call getKpiDefiniton again to get an updated list!
         */
        allowedQuantities: AllowedKpiQuantities | ((session: SessionType, settings: SettingsType) => AllowedKpiQuantities),

        /**
         * The path to the statistics for the case aggregation.
         * This can be a function that takes the settings as an argument.
         */
        caseStatisticsPath?: string | ((settings: SettingsType) => string),

        /**
         * These custom KPIs are needed for the product and time aggregation.
         * This can be a function that takes the settings as an argument.
         */
        productCustomKpis?: CustomKpi[] | ((settings: SettingsType, session?: SessionType) => CustomKpi[] | undefined),

        /**
         * These custom KPIs are needed for the case aggregation.
         * This can be a function that takes the settings as an argument.
         */
        caseCustomKpis?: CustomKpi[] | ((settings: SettingsType, session?: SessionType) => CustomKpi[] | undefined),

        /**
         * These custom KPIs are needed for the timeperiod aggregatrion.
         */
        eventOverTimeCustomKpis?: CustomKpi[] | ((session: SessionType, settings: SettingsType) => CustomKpi[] | undefined),

        /**
         * The path to the node statistics.
         * This can be a function that takes the settings as an argument.
         */
        nodeStatisticsPath?: string | PathDefinitions | ((settings: SettingsType, session?: SessionType) => string | PathDefinitions | undefined),

        /**
         * The path to the node equipment statistics.
         * This can be a function that takes the settings as an argument.
         */
        equipmentNodeStatsPath?: string | PathDefinitions | ((settings: SettingsType) => string | PathDefinitions | undefined),

        /**
         * These custom KPIs are needed for nodes.
         * This can be a function that takes the settings as an argument.
         */
        nodeCustomKpis?: CustomKpi[] | ((settings: SettingsType, session?: SessionType) => CustomKpi[] | undefined),

        /**
         * These custom KPIs are needed for edges.
         * This can be a function that takes the settings as an argument.
         */
        edgeCustomKpis?: CustomKpi[] | ((settings: SettingsType) => CustomKpi[] | undefined),

        /**
         * These custom KPIs are needed for equipment statistics.
         * This can be a function that takes the settings as an argument.
         */
        equipmentNodeCustomKpis?: CustomKpi[] | ((session: SessionType, settings: SettingsType) => CustomKpi[] | undefined),

        /**
         * The path to the edge statistics.
         * This can be a function that takes the settings as an argument.
         */
        edgeStatisticsPath?: string | PathDefinitions | ((settings: SettingsType) => string | PathDefinitions | undefined),

        /**
         * The path to the timeperiod statistics.
         * This can be a function that takes the settings as an argument.
         */
        edgeOverTimeCustomKpis?: CustomKpi[] | ((session: SessionType, settings: SettingsType) => CustomKpi[] | undefined),

        /**
         * The path to the edge statistics.
         * This can be a function that takes the settings as an argument.
         */
        edgeOverTimeStatisticsPath?: string | PathDefinitions | ((settings: SettingsType) => string | PathDefinitions | undefined),

        /**
         * The path to the equipment statistics.
         * This can be a function that takes the settings as an argument.
         */
        equipmentOverTimeStatisticsPath?: string | PathDefinitions | ((settings: SettingsType) => string | PathDefinitions | undefined),

        /**
         * These custom KPIs are needed for node time periods.
         * This can be a function that takes the settings as an argument.
         */
        nodeOverTimeCustomKpis?: CustomKpi[] | ((session: SessionType, settings: SettingsType) => CustomKpi[] | undefined),

        /**
         * The path to the node statistics.
         * This can be a function that takes the settings as an argument.
         */
        nodeOverTimeStatisticsPath?: string | PathDefinitions | ((settings: SettingsType) => string | PathDefinitions | undefined),

        /**
         * The path to the timeperiod statistics.
         */
        eventOverTimeStatisticsPath?: string | PathDefinitions | ((session: SessionType, settings: SettingsType) => string | PathDefinitions | undefined),
    }

type KpiDefinitionContext = { settings: SettingsType, session: SessionType };

const unitForTimes = { sum: Formatter.units.durationShort, mean: Formatter.units.timePerYield };

/**
 * When using the deviation API, we need to be sure that the eventKeys are labeled both in the actual and
 * the planned data. This helper checks whether the quantities are available in both datasets.
 */
const getAssignedQuantitiesDeviationHelper = (keysActual: EventKeys | undefined, keysPlanned: EventKeys | undefined, quantityType: QuantityType) => {
    const actual = getAssignedQuantities(keysActual, quantityType, false).map(q => q.baseQuantity);
    const planned = getAssignedQuantities(keysPlanned, quantityType, false).map(q => q.baseQuantity);
    return intersection(actual, planned);
};

const quantityHelper = (session: SessionType, caseQuantityType: QuantityType, nodeQuantityType: QuantityType) => {

    const getAssignedQuantitiesDeviation = (keysActual: EventKeys | undefined, keysPlanned: EventKeys | undefined, quantityType: QuantityType) => {
        const actual = getAssignedQuantities(keysActual, quantityType, false).map(q => q.baseQuantity);
        const planned = getAssignedQuantities(keysPlanned, quantityType, false).map(q => q.baseQuantity);
        return intersection(actual, planned);
    };

    return {
        actual: {
            case: getAssignedQuantities(session.project?.eventKeys, caseQuantityType, false).map(q => q.baseQuantity),
            node: getAssignedQuantities(session.project?.eventKeys, nodeQuantityType, false).map(q => q.baseQuantity),
            timeperiod: getAssignedQuantities(session.project?.eventKeys, nodeQuantityType, false).map(q => q.baseQuantity),
        },
        plan: {
            case: getAssignedQuantitiesDeviation(session.project?.eventKeys, session.project?.eventKeysPlan, caseQuantityType),
            node: getAssignedQuantitiesDeviation(session.project?.eventKeys, session.project?.eventKeysPlan, nodeQuantityType),
            timeperiod: [],
        }
    } as AllowedKpiQuantities;
};

// Don't ever export this! Use getKpiDefinition instead.
const kpiMap = new Map<KpiTypes, KpiDefinitionWithFunctions>();

/**
 * On-time delivery is the ratio of the number of cases that were delivered on
 * time to the total number of cases.
 */
kpiMap.set(
    KpiTypes.OnTimeDelivery,
    {
        label: "common.onTimeDelivery",
        unit: Formatter.units.percent,
        allowedStatistics: [StatisticTypes.Mean],
        isQuantityDependent: false,
        allowedQuantities: noAllowedKpiQuantities,
        spotlightId: "Kpi-OnTimeDelivery",
        isLessBetter: false,
        dataGapFilling: DataGapFilling.Zero,

        timeperiodApi: TimeperiodApis.Case,

        allowedComparisons: [KpiComparisons.None],

        apiParameters: {
            calculateTimeAndFreqStats: true,
        },

        caseCustomKpis: [{
            id: "isOnTime",
            definition: "(cases.endTime <= cases.internalDueDate)",
            target: "cases",
        }],
        caseStatisticsPath: "customKpis.isOnTime.value",

        productCustomKpis: (settings) => {
            const aggregation = settings.kpi.aggregation === AggregationTypes.Time ? "timeperiods" : "products";

            return [{
                id: "isOnTime",
                definition: "cases.endTime <= cases.internalDueDate",
                statistics: {
                    aggs: [AggTypes.P25, AggTypes.P75, AggTypes.Min, AggTypes.Max, AggTypes.Mean, AggTypes.Median, AggTypes.Sum],
                },
                target: aggregation,
            }];
        },
        productStatisticsPath: {
            mean: "customKpis.isOnTime.statistics.mean",
        },

        requiredEventKeys: ["uploads.cases.columnMapping.internalDueDate"],
    }
);

/**
 * Net transition time
 */
kpiMap.set(
    KpiTypes.NetTransitionTime,
    {
        label: "common.netTransitionTime",
        unit: Formatter.units.durationShort,
        allowedStatistics: allStats,
        isQuantityDependent: false,
        allowedQuantities: noAllowedKpiQuantities,
        spotlightId: "Kpi-NetTransitionTime",
        isLessBetter: true,
        timeperiodApi: TimeperiodApis.Case,
        allowedComparisons: [KpiComparisons.None],
        apiParameters: { calculateNetEdgeTimes: true },

        edgeStatisticsPath: "netEdgeTimeStatistics",

        requiredEventKeys: (session) => {
            // Only allow if net transition times can actually be calculated
            if (!session.project?.features?.allowNetTimes || session.project?.uploads?.shiftCalendar === undefined)
                return ["kpiNotAllowed"];

            return [];
        }
    }
);

/**
 * Net throughput time
 */
kpiMap.set(
    KpiTypes.NetThroughputTime,
    {
        label: "common.netThroughputTime",
        unit: Formatter.units.durationShort,
        allowedStatistics: allStats,
        isQuantityDependent: false,
        allowedQuantities: noAllowedKpiQuantities,
        spotlightId: "Kpi-NetThroughputTime",
        isLessBetter: true,
        timeperiodApi: TimeperiodApis.Case,
        allowedComparisons: [KpiComparisons.None],
        apiParameters: { calculateNetEdgeTimes: true, calculateNetPassTimes: true, calculateTimeAndFreqStats: true },
        dataGapFilling: DataGapFilling.Zero,
        nodeStatisticsPath: "netActivityPassTimeStatistics",
        edgeStatisticsPath: "netEdgeTimeStatistics",

        nodeOverTimeStatisticsPath: (settings) => {
            return "netActivityPassTimeStatistics." + settings.kpi.statistic.toString();
        },

        requiredEventKeys: (session) => {
            // Only allow if net transition times can actually be calculated
            if (!session.project?.features?.allowNetTimes || session.project?.uploads?.shiftCalendar === undefined)
                return ["kpiNotAllowed"];

            return [];
        }
    }
);

/**
 * Number of delayed orders
 */

kpiMap.set(
    KpiTypes.NumberOfDelayedOrders,
    {
        label: "common.numberOfDelayedOrders",
        unit: Formatter.units.numberShort,
        allowedStatistics: [StatisticTypes.Sum],
        isQuantityDependent: false,
        allowedQuantities: noAllowedKpiQuantities,
        spotlightId: "Kpi-NumberOfDelayedOrders",
        isLessBetter: true,
        timeperiodApi: TimeperiodApis.Case,

        allowedComparisons: [KpiComparisons.None],

        caseCustomKpis: [{
            id: "isOnTime",
            definition: "(cases.endTime < cases.internalDueDate)",
            target: "cases",
        }],
        caseStatisticsPath: "customKpis.isOnTime.value",

        productCustomKpis: (settings) => {
            const aggregation = settings.kpi.aggregation === AggregationTypes.Time ? "timeperiods" : "products";

            return [{
                id: "isOnTime",
                definition: "cases.endTime < cases.internalDueDate",
                statistics: {
                    aggs: [AggTypes.Sum],
                },
                target: aggregation,
            }, {
                id: "numberOfDelayedOrders",
                definition: "products.count - products.customKpis.isOnTime.statistics.sum",
                target: aggregation,
            }];
        },
        productStatisticsPath: {
            sum: "customKpis.numberOfDelayedOrders.value",
        },

        requiredEventKeys: ["uploads.cases.columnMapping.internalDueDate"],
    }
);


/**
 * The delay time (renamed to order delay time) is the time that an order is delayed. It is zero if it finishes before time.
 */
kpiMap.set(
    KpiTypes.DelayTime,
    {
        label: "common.delayTime",
        unit: Formatter.units.durationShort,
        allowedStatistics: [StatisticTypes.Sum, StatisticTypes.Mean, StatisticTypes.Median, StatisticTypes.Variance],
        isQuantityDependent: false,
        allowedQuantities: noAllowedKpiQuantities,
        spotlightId: "Kpi-DelayTime",
        isLessBetter: true,
        timeperiodApi: TimeperiodApis.Case,
        dataGapFilling: DataGapFilling.Zero,

        allowedComparisons: [KpiComparisons.None],

        caseCustomKpis: [{
            id: "delayTime",
            definition: "(cases.endTime > cases.internalDueDate) * ((cases.endTime - cases.internalDueDate) / s)",
            target: "cases",
        }],
        caseStatisticsPath: "customKpis.delayTime.value",

        productCustomKpis: (settings) => {
            const aggregation = settings.kpi.aggregation === AggregationTypes.Time ? "timeperiods" : "products";

            return [{
                id: "delayTime",
                definition: "(cases.endTime > cases.internalDueDate) * ((cases.endTime - cases.internalDueDate) / s)",
                statistics: { aggs: [AggTypes.P25, AggTypes.P75, AggTypes.Mean, AggTypes.Median, AggTypes.Min, AggTypes.Max] },
                target: aggregation,
            }];
        },
        productStatisticsPath: {
            mean: "customKpis.delayTime.statistics.mean",
            median: "customKpis.delayTime.statistics.median",
            variance: "customKpis.delayTime.statistics",
            sum: "customKpis.delayTime.statistics.sum",
        },

        requiredEventKeys: ["uploads.cases.columnMapping.internalDueDate"],
    }
);

/**
 * The throughput time is used for case statistics.
 * It can be aggregated for cases, products and over time.
 *
 * KPI ID 1001
 */
kpiMap.set(
    KpiTypes.ThroughputTime,
    {
        label: "common.throughputTime",
        labelPlan: "common.plannedThroughputTime",
        unit: Formatter.units.durationShort,
        allowedStatistics: allStats,
        isQuantityDependent: false,
        allowedQuantities: noAllowedKpiQuantities,
        spotlightId: "Kpi-ThroughputTime",
        apiParameters: {
            calculateTimeAndFreqStats: true
        },
        dataGapFilling: DataGapFilling.Zero,

        // Definitions for the product benchmarking section
        isLessBetter: true,
        allowedComparisons: noPlanningForRoutings,
        productStatisticsPath: "durationStatistics",
        caseStatisticsPath: "duration",

        // Defintions for the value stream section
        nodeStatisticsPath: (settings) => {
            if (groupingKeysPassCompatibleGroups.includes(settings.groupingKey))
                return "activityPassTimeStatistics";
            return "timeStatistics";
        },

        nodeOverTimeStatisticsPath: (settings) => {
            if (groupingKeysPassCompatibleGroups.includes(settings.groupingKey))
                return "activityPassTimeStatistics." + settings.kpi.statistic.toString();
            return "timeStatistics." + settings.kpi.statistic.toString();
        },

        edgeStatisticsPath: "timeStatistics",
    }
);

/**
 * The net throughput time is used for case statistics.
 * It can be aggregated for cases, products and over time.
 *
 * KPI ID ???
 */
kpiMap.set(
    KpiTypes.OrderNetThroughputTime,
    {
        label: "common.netThroughputTime",
        unit: Formatter.units.durationShort,
        allowedStatistics: allStats,
        isQuantityDependent: false,
        allowedQuantities: noAllowedKpiQuantities,
        spotlightId: "Kpi-OrderNetThroughputTime",
        apiParameters: {
            calculateTimeAndFreqStats: true,
        },
        dataGapFilling: DataGapFilling.Zero,

        requiredEventKeys: (session) => {
            // Only allow if net transition times can actually be calculated
            if (!session.project?.features?.allowNetTimes || session.project?.uploads?.organizationShiftCalendar === undefined)
                return ["kpiNotAllowed"];

            return [];
        },

        // Definitions for the product benchmarking section
        isLessBetter: true,
        allowedComparisons: [KpiComparisons.None, KpiComparisons.BestProcesses],
        productStatisticsPath: "netDurationStatistics",
        caseStatisticsPath: "netDuration",
    }
);

/**
 * The deviation throughput time is used for case statistics.
 * It is the difference between the actual and the planned throughput time.
 *
 * THIS KPI IS UNAVAILABLE FOR PROJECTS WITH ROUTINGS!
 *
 * KPI ID ????
 */
kpiMap.set(
    KpiTypes.DeviationThroughputTime,
    {
        label: "workflows.planningDeviation.passTimeDeviation",
        unit: Formatter.units.durationShort,
        allowedStatistics: statsWithoutSum,
        apiParameters: {
            calculateTimeAndFreqStats: true,
            calculateDeviations: true,
        },
        allowedComparisons: [KpiComparisons.None],
        isQuantityDependent: false,
        allowedQuantities: noAllowedKpiQuantities,
        dataGapFilling: DataGapFilling.Zero,
        spotlightId: "Kpi-DeviationThroughputTime",
        isLessBetter: true,
        timeperiodApi: TimeperiodApis.CaseDeviation,
        requiresPlanningData: true,
        productStatisticsPath: "deviation.durationStatistics",
        caseStatisticsPath: "deviation.duration",
        requiredEventKeys: (session) => {
            return session.project?.uploads?.routings !== undefined ? ["kpiNotAllowed"] : [];
        },
    }
);

/**
 * The deviation throughput time is used for case statistics. It is the difference between the
 * actual and the planned throughput time.
 *
 * THIS KPI IS UNAVAILABLE FOR PROJECTS WITH ROUTINGS!
 *
 * KPI ID ????
 */
kpiMap.set(
    KpiTypes.DeviationRelativeThroughputTime,
    {
        label: "workflows.planningDeviation.passTimeDeviationRelative",
        unit: Formatter.units.percent,
        allowedStatistics: statsWithoutSum,
        allowedComparisons: [KpiComparisons.None],
        isQuantityDependent: false,
        allowedQuantities: noAllowedKpiQuantities,
        apiParameters: {
            calculateTimeAndFreqStats: true,
            calculateDeviations: true,
        },
        spotlightId: "Kpi-DeviationRelativeThroughputTime",
        isLessBetter: true,
        timeperiodApi: TimeperiodApis.CaseDeviation,
        requiresPlanningData: true,
        productStatisticsPath: "deviation.relativeToPlanned.durationStatistics",
        caseStatisticsPath: "deviation.relativeToPlanned.duration",
        requiredEventKeys: (session) => {
            return session.project?.uploads?.routings !== undefined ? ["kpiNotAllowed"] : [];
        },
    }
);

/**
 * On-time start is the ratio of the number of passes that started on time to the total number of passes.
 */
kpiMap.set(
    KpiTypes.OnTimeStart,
    {
        label: "common.onTimeStart",
        unit: Formatter.units.percent,
        allowedStatistics: [StatisticTypes.Mean, StatisticTypes.Median],
        isQuantityDependent: false,
        isPassDeviation: true,
        allowedQuantities: noAllowedKpiQuantities,
        spotlightId: "Kpi-OnTimeStart",
        isLessBetter: false,
        allowedComparisons: [KpiComparisons.None],
        apiParameters: {
            calculateTimeAndFreqStats: true,
        },
        nodeCustomKpis: [
            {
                id: "onTimeStart",
                definition: "events.startTime < planned.events.startTime",
                statistics: { aggs: [AggTypes.Min, AggTypes.Mean, AggTypes.Median, AggTypes.P25, AggTypes.P75, AggTypes.Max] },
                target: "graph.nodes"
            },
        ],
        nodeStatisticsPath: "customKpis.onTimeStart.statistics",

        requiredEventKeys: ["uploads.plannedPasses.id", "uploads.plannedPasses.columnMapping.startTime",
            "uploads.plannedPasses.columnMapping.caseId", "uploads.plannedPasses.columnMapping.passId"],
    }
);

/**
 * On-time end is the ratio of the number of passes that ended before the planned end time to the total number of passes.
 */
kpiMap.set(
    KpiTypes.OnTimeEnd,
    {
        label: "common.onTimeEnd",
        unit: Formatter.units.percent,
        allowedStatistics: [StatisticTypes.Mean, StatisticTypes.Median],
        isQuantityDependent: false,
        isPassDeviation: true,
        allowedQuantities: noAllowedKpiQuantities,
        spotlightId: "Kpi-OnTimeEnd",
        isLessBetter: false,
        allowedComparisons: [KpiComparisons.None],
        apiParameters: {
            calculateTimeAndFreqStats: true,
        },
        nodeCustomKpis: [
            {
                id: "onTimeEnd",
                definition: "events.endTime < planned.events.endTime",
                statistics: { aggs: [AggTypes.Min, AggTypes.Mean, AggTypes.Median, AggTypes.P25, AggTypes.P75, AggTypes.Max] },
                target: "graph.nodes"
            },
        ],
        nodeStatisticsPath: "customKpis.onTimeEnd.statistics",

        requiredEventKeys: ["uploads.plannedPasses.columnMapping.endTime", "uploads.plannedPasses.id",
            "uploads.plannedPasses.columnMapping.caseId", "uploads.plannedPasses.columnMapping.passId"],
    }
);

/**
 * Start time deviation is the difference between the actual and the planned start time of a pass.
 */
kpiMap.set(
    KpiTypes.StartTimeDeviation,
    {
        label: "common.startTimeDeviation",
        unit: Formatter.units.durationShort,
        allowedStatistics: [StatisticTypes.Mean, StatisticTypes.Median, StatisticTypes.Sum, StatisticTypes.Variance],
        isQuantityDependent: false,
        isPassDeviation: true,
        allowedQuantities: noAllowedKpiQuantities,
        spotlightId: "Kpi-StartTimeDeviation",
        isLessBetter: false,
        allowedComparisons: [KpiComparisons.None],
        apiParameters: {
            calculateTimeAndFreqStats: true,
        },
        nodeCustomKpis: [
            {
                id: "startTimeDeviation",
                definition: "(events.startTime - planned.events.startTime) / s",
                statistics: { aggs: [AggTypes.Min, AggTypes.Mean, AggTypes.Median, AggTypes.P25, AggTypes.P75, AggTypes.Max, AggTypes.Sum] },
                target: "graph.nodes"
            },
        ],
        nodeStatisticsPath: "customKpis.startTimeDeviation.statistics",

        requiredEventKeys: ["uploads.plannedPasses.columnMapping.startTime", "uploads.plannedPasses.id",
            "uploads.plannedPasses.columnMapping.caseId", "uploads.plannedPasses.columnMapping.passId"],
    }
);

/**
 * End time deviation is the difference between the actual and the planned end time of a pass.
 */
kpiMap.set(
    KpiTypes.EndTimeDeviation,
    {
        label: "common.endTimeDeviation",
        unit: Formatter.units.durationShort,
        allowedStatistics: [StatisticTypes.Mean, StatisticTypes.Median, StatisticTypes.Sum, StatisticTypes.Variance],
        isQuantityDependent: false,
        isPassDeviation: true,
        allowedQuantities: noAllowedKpiQuantities,
        spotlightId: "Kpi-EndTimeDeviation",
        isLessBetter: false,
        allowedComparisons: [KpiComparisons.None],
        apiParameters: {
            calculateTimeAndFreqStats: true,
        },
        nodeCustomKpis: [
            {
                id: "endTimeDeviation",
                definition: "(events.endTime - planned.events.endTime) / s",
                statistics: { aggs: [AggTypes.Min, AggTypes.Mean, AggTypes.Median, AggTypes.P25, AggTypes.P75, AggTypes.Max, AggTypes.Sum] },
                target: "graph.nodes"
            },
        ],
        nodeStatisticsPath: "customKpis.endTimeDeviation.statistics",

        requiredEventKeys: ["uploads.plannedPasses.columnMapping.endTime", "uploads.plannedPasses.id",
            "uploads.plannedPasses.columnMapping.caseId", "uploads.plannedPasses.columnMapping.passId"],
    }
);


/**
 * The production process ratio is an important indicator in lean management.
 * It is defined as the ratio of production time to throughput time.
 * It can be aggregated for cases, products and over time.
 *
 * KPI ID 3106
 */
kpiMap.set(
    KpiTypes.ProductionProcessRatio,
    {
        label: "kpi.relativeCaseProductionTime",
        labelPlan: "kpi.plannedRelativeCaseProductionTime",
        unit: Formatter.units.percent,
        requiredEventKeys: ["eventKeys.isProduction"],
        apiParameters: { calculateProductionStats: true, calculateTimeAndFreqStats: true },
        allowedStatistics: statsWithoutSum,
        allowedComparisons: noPlanningForRoutings,
        dataGapFilling: DataGapFilling.Zero,
        isQuantityDependent: false,
        allowedQuantities: noAllowedKpiQuantities,
        spotlightId: "Kpi-ProductionProcessRatio",
        isLessBetter: false,
        productStatisticsPath: "relativeCaseProductionTimeStatistics",
        caseStatisticsPath: "kpis.relativeCaseProductionTime",
    }
);



const removeBestProcessForTime = (comparisons: KpiComparisons[]) => {
    return (_: SessionType, settings: SettingsType) => {
        if (settings.kpi.aggregation === AggregationTypes.Time)
            return comparisons.filter(c => c !== KpiComparisons.BestProcesses);

        return comparisons;
    };
};

function getNodeOverTimePath(settings: SettingsType, timeType: TimeTypes, divideByMachineNumber = false) {
    return {
        sum: `${timeType}TimeStatistics.sum`,
        mean: groupingKeysPassCompatibleGroups.includes(settings.groupingKey) ? `customKpis.${timeType}TimePerOutput${divideByMachineNumber ? "OverMachines" : ""}.value`
            : "customKpis.relativeConfirmationTime.value",
        median: `customKpis.${timeType}TimePerOutputStatistics.statistics.median`
    };
}

function getTimeAggregationComparisons(comparisons: KpiComparisons[] = [KpiComparisons.Planning, KpiComparisons.BestProcesses, KpiComparisons.None]) {
    return (session: SessionType, settings: SettingsType) => {
        const isTimeAggregation = settings.kpi.aggregation === AggregationTypes.Time;

        if (isTimeAggregation) {
            return [KpiComparisons.None];
        }

        return [KpiComparisons.None, KpiComparisons.Planning, KpiComparisons.BestProcesses].filter(k => comparisons.includes(k));
    };
}

/**
 * The busy time is the busy time relative to the produced quantity.
 * In the ISO it is named actual busy time (AUBT) or planned busy time (PBT).
 * It can be aggregated for cases, products and over time.
 * Also it it is calculated for nodes.
 *
 * KPI ID 1002
 */
kpiMap.set(
    KpiTypes.BusyTime,
    {
        label: "common.busyTime",
        labelPlan: "common.plannedBusy",
        unit: unitForTimes,
        allowedStatistics: allStats,
        apiParameters: { calculateBusyStats: true, calculateOutputStats: true, calculateTimeAndFreqStats: true, calculateProductionStats: true, calculateFailureStats: true, calculateInterruptionStats: true, calculateSetupStats: true },
        isQuantityDependent: true,
        allowedQuantities: (session: SessionType) =>
            quantityHelper(session, QuantityType.CaseYield, QuantityType.Yield),
        spotlightId: "Kpi-BusyTime",

        timeperiodApi: TimeperiodApis.Event,
        dataGapFilling: DataGapFilling.Zero,

        // Definitions for the product benchmarking section
        allowedComparisons: getTimeAggregationComparisons(),
        isLessBetter: true,
        caseCustomKpis: getCaseCustomKpiForTimeType("busy"),
        caseStatisticsPath: "busyTimeStatistics.sum",
        productStatisticsPath: getProductPathForTimeType("busy"),
        productCustomKpis: getProductCustomKpiForTimeType("busy"),

        eventOverTimeStatisticsPath: getEventOverTimePathForTimeType("busy"),
        eventOverTimeCustomKpis: getEventOverTimeCustomKpisForTimeType("busy"),

        equipmentNodeCustomKpis: getEventOverTimeCustomKpisForTimeType("busy", true),

        // Definitions for the value stream section
        nodeStatisticsPath: getNodePathForTimeType("busy"),
        nodeCustomKpis: getNodeCustomKpiForTimeType("busy"),

        nodeOverTimeCustomKpis: getNodeOverTimeCustomKpisForTimeType("busy"),
        nodeOverTimeStatisticsPath: (s) => getNodeOverTimePath(s, "busy"),
        edgeStatisticsPath: "timeStatistics",
        equipmentOverTimeStatisticsPath: { sum: "busyTimeStatistics.total" },
        isWipIncluded: (session) => !!session.project?.eventKeys?.isWip,
    }
);


/**
 * The production time is the time that actually something is produced on workplaces relative to the produced quantity.
 * In the ISO it is named actual production time (APT) or planned production time (PPT).
 * It can be aggregated for cases, products and over time.
 * Also it it is calculated for nodes.
 *
 * KPI ID 1008
 */
kpiMap.set(
    KpiTypes.ProductionTime,
    {
        label: "common.productionTime",
        labelPlan: "common.plannedProduction",
        unit: unitForTimes,
        indentationLevel: 0,
        isQuantityDependent: true,
        requiredEventKeys: ["eventKeys.isProduction"],

        // Output is inferred from yield
        allowedQuantities: (session: SessionType) =>
            quantityHelper(session, QuantityType.CaseYield, QuantityType.Yield),

        timeperiodApi: TimeperiodApis.Event,
        dataGapFilling: DataGapFilling.Zero,

        spotlightId: "Kpi-ProductionTime",
        allowedStatistics: allStats,
        apiParameters: { calculateProductionStats: true, calculateOutputStats: true, calculateTimeAndFreqStats: true },
        allowedComparisons: getTimeAggregationComparisons(),
        isLessBetter: true,
        caseCustomKpis: getCaseCustomKpiForTimeType("production"),
        caseStatisticsPath: "productionTimeStatistics.sum",
        productStatisticsPath: getProductPathForTimeType("production"),
        productCustomKpis: getProductCustomKpiForTimeType("production"),

        eventOverTimeStatisticsPath: getEventOverTimePathForTimeType("production"),
        eventOverTimeCustomKpis: getEventOverTimeCustomKpisForTimeType("production"),

        equipmentNodeCustomKpis: getEventOverTimeCustomKpisForTimeType("production", true),

        equipmentNodeStatsPath: getEquipmentNodeStatsPath("production"),

        // Definitions for the value stream section
        nodeStatisticsPath: getNodePathForTimeType("production"),

        nodeOverTimeCustomKpis: getNodeOverTimeCustomKpisForTimeType("production"),
        nodeOverTimeStatisticsPath: (s) => getNodeOverTimePath(s, "production"),
        nodeCustomKpis: getNodeCustomKpiForTimeType("production"),
        edgeStatisticsPath: "timeStatistics",
        equipmentOverTimeStatisticsPath: { sum: "productionTimeStatistics.total" },
        isWipIncluded: (session) => !!session.project?.eventKeys?.isWip,
    }
);

/**
 * The technical losses is the sum of (unplanned preventive maintenance, corrective maintenance, non-active maintenance
 * and other technical losses) kpis.
 * KPI ID 1015
 */
kpiMap.set(
    KpiTypes.TechnicalLosses,
    {
        label: "common.technicalLosses",
        unit: unitForTimes,
        isQuantityDependent: true,
        allowedQuantities: (session: SessionType) =>
            quantityHelper(session, QuantityType.CaseYield, QuantityType.Yield),

        spotlightId: "Kpi-TechnicalLosses",
        requiredEventKeys: (session: SessionType) => {
            let result: string[] = [];
            // To display the kpi in the dropdown, we need to check if at least one of the event keys listed here is included in the project's event keys.
            result = ["eventKeys.isUnplannedPreventiveMaintenance", "eventKeys.isCorrectiveMaintenance",
                "eventKeys.isNonActiveMaintenance", "eventKeys.isOtherTechnicalLosses"].filter(eventKey => get(session.project, eventKey) !== undefined);
            return result.length === 0 ? ["kpiNotAllowed"] : result;
        },
        indentationLevel: 1,
        apiParameters: { calculateFailureStats: true, calculateOutputStats: true, calculateTimeAndFreqStats: true },
        dataGapFilling: DataGapFilling.Zero,

        allowedComparisons: removeBestProcessForTime([KpiComparisons.None, KpiComparisons.BestProcesses]),
        allowedStatistics: allStats,
        isLessBetter: true,
        timeperiodApi: TimeperiodApis.Event,
        caseCustomKpis: getCaseCustomKpiForTimeType("technicalLosses"),
        caseStatisticsPath: "technicalLossesTimeStatistics.sum",
        productStatisticsPath: getProductPathForTimeType("technicalLosses"),
        productCustomKpis: getProductCustomKpiForTimeType("technicalLosses"),

        // Definitions for the value stream section
        nodeStatisticsPath: getNodePathForTimeType("technicalLosses"),
        nodeCustomKpis: getNodeCustomKpiForTimeType("technicalLosses"),

        eventOverTimeStatisticsPath: getEventOverTimePathForTimeType("technicalLosses"),
        eventOverTimeCustomKpis: getEventOverTimeCustomKpisForTimeType("technicalLosses"),

        equipmentNodeCustomKpis: getEventOverTimeCustomKpisForTimeType("technicalLosses", true),

        edgeStatisticsPath: "timeStatistics",

        equipmentNodeStatsPath: { sum: "technicalLossesTimeStatistics.total" },
        equipmentOverTimeStatisticsPath: { sum: "technicalLossesTimeStatistics.total" },

        nodeOverTimeCustomKpis: getNodeOverTimeCustomKpisForTimeType("technicalLosses"),
        nodeOverTimeStatisticsPath: (s) => getNodeOverTimePath(s, "technicalLosses"),
        isWipIncluded: (session) => !!session.project?.eventKeys?.isWip,
    }
);

/**
 * The Organizational Losses is the sum of (material shortage, delivery problem, authorization shortage, employee shortage
 * and other organizational losses) kpis.
 *
 * KPI ID 1013
 */
kpiMap.set(
    KpiTypes.OrganizationalLosses,
    {
        label: "common.organizationalLosses",
        unit: unitForTimes,
        isQuantityDependent: true,
        allowedQuantities: (session: SessionType) =>
            quantityHelper(session, QuantityType.CaseYield, QuantityType.Yield),

        spotlightId: "Kpi-OrganizationalLosses",
        requiredEventKeys: (session: SessionType) => {
            let result: string[] = [];

            result = ["eventKeys.isMaterialShortage", "eventKeys.isDeliveryProblem", "eventKeys.isAuthorizationShortage",
                "eventKeys.isEmployeeShortage", "eventKeys.isOtherOrganizationalLosses"].filter(eventKey => get(session.project, eventKey) !== undefined);
            return result.length === 0 ? ["kpiNotAllowed"] : result;
        },
        indentationLevel: 1,
        apiParameters: { calculateFailureStats: true, calculateOutputStats: true, calculateTimeAndFreqStats: true },
        dataGapFilling: DataGapFilling.Zero,

        allowedComparisons: removeBestProcessForTime([KpiComparisons.None, KpiComparisons.BestProcesses]),
        allowedStatistics: allStats,
        isLessBetter: true,
        timeperiodApi: TimeperiodApis.Event,
        caseCustomKpis: getCaseCustomKpiForTimeType("organizationalLosses"),
        caseStatisticsPath: "organizationalLossesTimeStatistics.sum",
        productStatisticsPath: getProductPathForTimeType("organizationalLosses"),
        productCustomKpis: getProductCustomKpiForTimeType("organizationalLosses"),

        // Definitions for the value stream section
        nodeStatisticsPath: getNodePathForTimeType("organizationalLosses"),
        nodeCustomKpis: getNodeCustomKpiForTimeType("organizationalLosses"),

        eventOverTimeStatisticsPath: getEventOverTimePathForTimeType("organizationalLosses"),
        eventOverTimeCustomKpis: getEventOverTimeCustomKpisForTimeType("organizationalLosses"),

        equipmentNodeCustomKpis: getEventOverTimeCustomKpisForTimeType("organizationalLosses", true),

        edgeStatisticsPath: "timeStatistics",

        equipmentNodeStatsPath: { sum: "organizationalLossesTimeStatistics.total" },
        equipmentOverTimeStatisticsPath: { sum: "organizationalLossesTimeStatistics.total" },

        nodeOverTimeCustomKpis: getNodeOverTimeCustomKpisForTimeType("organizationalLosses"),
        nodeOverTimeStatisticsPath: (s) => getNodeOverTimePath(s, "organizationalLosses"),
        isWipIncluded: (session) => !!session.project?.eventKeys?.isWip,
    }
);

/**
 * The Process Losses kpi.
 *
 * KPI ID 1024
 */
kpiMap.set(
    KpiTypes.ProcessLosses,
    {
        label: "common.processLosses",
        unit: unitForTimes,
        isQuantityDependent: true,
        allowedQuantities: (session: SessionType) =>
            quantityHelper(session, QuantityType.CaseYield, QuantityType.Yield),

        spotlightId: "Kpi-ProcessLosses",
        requiredEventKeys: (session: SessionType) => {
            let result: string[] = [];

            result = ["eventKeys.isProcessLosses"].filter(eventKey => get(session.project, eventKey) !== undefined);
            return result.length === 0 ? ["kpiNotAllowed"] : result;
        },
        indentationLevel: 1,
        apiParameters: { calculateFailureStats: true, calculateOutputStats: true, calculateTimeAndFreqStats: true },
        dataGapFilling: DataGapFilling.Zero,
        allowedComparisons: removeBestProcessForTime([KpiComparisons.None, KpiComparisons.BestProcesses]),
        allowedStatistics: allStats,
        isLessBetter: true,
        timeperiodApi: TimeperiodApis.Event,
        caseCustomKpis: getCaseCustomKpiForTimeType("processLosses"),
        caseStatisticsPath: "processLossesTimeStatistics.sum",
        productStatisticsPath: getProductPathForTimeType("processLosses"),
        productCustomKpis: getProductCustomKpiForTimeType("processLosses"),

        // Definitions for the value stream section
        nodeStatisticsPath: getNodePathForTimeType("processLosses"),
        nodeCustomKpis: getNodeCustomKpiForTimeType("processLosses"),

        eventOverTimeStatisticsPath: getEventOverTimePathForTimeType("processLosses"),
        eventOverTimeCustomKpis: getEventOverTimeCustomKpisForTimeType("processLosses"),

        equipmentNodeCustomKpis: getEventOverTimeCustomKpisForTimeType("processLosses", true),

        edgeStatisticsPath: "timeStatistics",

        equipmentNodeStatsPath: { sum: "processLossesTimeStatistics.total" },
        equipmentOverTimeStatisticsPath: { sum: "processLossesTimeStatistics.total" },

        nodeOverTimeCustomKpis: getNodeOverTimeCustomKpisForTimeType("processLosses"),
        nodeOverTimeStatisticsPath: (s) => getNodeOverTimePath(s, "processLosses"),
        isWipIncluded: (session) => !!session.project?.eventKeys?.isWip,
    }
);

/**
 * The Quality Losses kpi.
 *
 * KPI ID 1028
 */
kpiMap.set(
    KpiTypes.QualityLosses,
    {
        label: "common.qualityLosses",
        unit: unitForTimes,
        isQuantityDependent: true,
        allowedQuantities: (session: SessionType) =>
            quantityHelper(session, QuantityType.CaseYield, QuantityType.Yield),

        spotlightId: "Kpi-QualityLosses",
        requiredEventKeys: (session: SessionType) => {
            let result: string[] = [];

            result = ["eventKeys.isQualityLosses"].filter(eventKey => get(session.project, eventKey) !== undefined);
            return result.length === 0 ? ["kpiNotAllowed"] : result;
        },
        indentationLevel: 1,
        apiParameters: { calculateFailureStats: true, calculateOutputStats: true, calculateTimeAndFreqStats: true },
        dataGapFilling: DataGapFilling.Zero,

        allowedComparisons: removeBestProcessForTime([KpiComparisons.None, KpiComparisons.BestProcesses]),
        allowedStatistics: allStats,
        isLessBetter: true,
        timeperiodApi: TimeperiodApis.Event,
        caseCustomKpis: getCaseCustomKpiForTimeType("qualityLosses"),
        caseStatisticsPath: "qualityLossesTimeStatistics.sum",
        productStatisticsPath: getProductPathForTimeType("qualityLosses"),
        productCustomKpis: getProductCustomKpiForTimeType("qualityLosses"),

        // Definitions for the value stream section
        nodeStatisticsPath: getNodePathForTimeType("qualityLosses"),
        nodeCustomKpis: getNodeCustomKpiForTimeType("qualityLosses"),

        eventOverTimeStatisticsPath: getEventOverTimePathForTimeType("qualityLosses"),
        eventOverTimeCustomKpis: getEventOverTimeCustomKpisForTimeType("qualityLosses"),

        equipmentNodeCustomKpis: getEventOverTimeCustomKpisForTimeType("qualityLosses", true),

        edgeStatisticsPath: "timeStatistics",

        equipmentNodeStatsPath: { sum: "qualityLossesTimeStatistics.total" },
        equipmentOverTimeStatisticsPath: { sum: "qualityLossesTimeStatistics.total" },

        nodeOverTimeCustomKpis: getNodeOverTimeCustomKpisForTimeType("qualityLosses"),
        nodeOverTimeStatisticsPath: (s) => getNodeOverTimePath(s, "qualityLosses"),
        isWipIncluded: (session) => !!session.project?.eventKeys?.isWip,
    }
);

/**
 * The cycle time is the same as the production time but we do not show a sum.
 *
 * KPI ID 2001
 */
kpiMap.set(
    KpiTypes.CycleTime,
    {
        ...kpiMap.get(KpiTypes.ProductionTime)!, // copy all properties from production time
        label: "common.cycleTime",
        spotlightId: "Kpi-CycleTime",
        indentationLevel: undefined,
        allowedStatistics: statsWithoutSum,
        dataGapFilling: DataGapFilling.Zero,
        allowedComparisons: [KpiComparisons.Planning, KpiComparisons.BestProcesses, KpiComparisons.None],
        equipmentNodeStatsPath: { mean: "customKpis.cycleTime.value" },
        equipmentNodeCustomKpis: (_session, settings) => {
            if (settings.quantity === undefined)
                return undefined;
            return [{
                id: "cycleTime",
                definition: `equipment.productionTimeStatistics.total / equipment.output${capitalize(settings.quantity)}Statistics.sum`,
                target: "equipment"
            }];
        },
        apiParameters: {
            calculateProductionStats: true,
            calculateOutputStats: true,
            calculateTimeAndFreqStats: true,
            calculateActivityValues: true
        },
        productStatisticsPath: getProductPathForTimeType("production", true),
        nodeStatisticsPath: getNodePathForTimeType("production", true, true),
        nodeCustomKpis: getNodeCustomKpiForTimeType("production", true),
        equipmentOverTimeStatisticsPath: { mean: "customKpis.cycleTime.value" },
    }
);

/**
 * The setup time is the time during which a setup takes place.
 * In the ISO it is named actual setup time (ASUT) or planned setup time (PSUT).
 * It can be aggregated for cases, products and over time.
 * Also it it is calculated for nodes.
 *
 * KPI ID 1009
 */
kpiMap.set(
    KpiTypes.SetupTime,
    {
        label: "common.setupTime",
        labelPlan: "common.plannedSetup",
        unit: unitForTimes,
        isQuantityDependent: true,

        // Output is inferred from yield
        allowedQuantities: (session: SessionType) =>
            quantityHelper(session, QuantityType.CaseYield, QuantityType.Yield),

        timeperiodApi: TimeperiodApis.Event,
        dataGapFilling: DataGapFilling.Zero,

        eventOverTimeStatisticsPath: getEventOverTimePathForTimeType("setup"),
        eventOverTimeCustomKpis: getEventOverTimeCustomKpisForTimeType("setup"),

        equipmentNodeCustomKpis: getEventOverTimeCustomKpisForTimeType("setup", true),

        spotlightId: "Kpi-SetupTime",
        requiredEventKeys: ["eventKeys.isSetup"],
        indentationLevel: 0,
        apiParameters: { calculateSetupStats: true, calculateOutputStats: true, calculateTimeAndFreqStats: true },
        allowedStatistics: allStats,
        allowedComparisons: getTimeAggregationComparisons(),
        isLessBetter: true,
        caseCustomKpis: getCaseCustomKpiForTimeType("setup"),
        caseStatisticsPath: "setupTimeStatistics.sum",
        productStatisticsPath: getProductPathForTimeType("setup"),
        productCustomKpis: getProductCustomKpiForTimeType("setup"),

        equipmentNodeStatsPath: getEquipmentNodeStatsPath("setup"),

        // Definitions for the value stream section
        nodeStatisticsPath: getNodePathForTimeType("setup"),
        nodeCustomKpis: getNodeCustomKpiForTimeType("setup"),
        edgeStatisticsPath: "timeStatistics",
        equipmentOverTimeStatisticsPath: { sum: "setupTimeStatistics.total" },

        nodeOverTimeCustomKpis: getNodeOverTimeCustomKpisForTimeType("setup"),
        nodeOverTimeStatisticsPath: (s) => getNodeOverTimePath(s, "setup"),
        isWipIncluded: (session) => !!session.project?.eventKeys?.isWip,
    }
);

kpiMap.set(
    KpiTypes.SetupTimePerPass,
    {
        label: "common.setupTimePerPass",
        unit: Formatter.units.durationShort,
        isQuantityDependent: false,
        allowedQuantities: noAllowedKpiQuantities,
        spotlightId: "Kpi-SetupTimePerPass",
        allowedStatistics: [StatisticTypes.Mean, StatisticTypes.Median],
        allowedComparisons: [KpiComparisons.None, KpiComparisons.Planning],
        isLessBetter: true,
        nodeStatisticsPath: "setupTimeStatistics"
    }
);

kpiMap.set(
    KpiTypes.ProductionTimePerPass,
    {
        label: "common.productionTimePerPass",
        unit: Formatter.units.durationShort,
        isQuantityDependent: false,
        allowedQuantities: noAllowedKpiQuantities,
        spotlightId: "Kpi-ProductionTimePerPass",
        allowedStatistics: [StatisticTypes.Mean, StatisticTypes.Median],
        allowedComparisons: [KpiComparisons.None, KpiComparisons.Planning],
        isLessBetter: true,
        nodeStatisticsPath: "productionTimeStatistics"
    }
);

kpiMap.set(
    KpiTypes.SetupGap,
    {
        label: "common.setupTime",
        labelPlan: "common.plannedSetup",
        unit: Formatter.units.durationShort,
        isQuantityDependent: false,
        timeperiodApi: TimeperiodApis.Case,
        allowedQuantities: noAllowedKpiQuantities,
        spotlightId: "Kpi-SetupGap",
        allowedStatistics: [StatisticTypes.Mean, StatisticTypes.Median],
        allowedComparisons: [KpiComparisons.None, KpiComparisons.Planning],
        isLessBetter: true,
    }
);

/**
 * The interruption time is the time during which an interruption takes place.
 * It is not explicitly mentioned in the ISO and may be deprecated.
 * It can be aggregated for cases, products and over time.
 * Also it it is calculated for nodes.
 *
 * KPI ID 1003
 */
kpiMap.set(
    KpiTypes.InterruptionTime,
    {
        label: "common.interruptionTime",
        unit: unitForTimes,
        isQuantityDependent: true,

        allowedQuantities: (session: SessionType) =>
            quantityHelper(session, QuantityType.CaseYield, QuantityType.Yield),

        spotlightId: "Kpi-InterruptionTime",
        requiredEventKeys: ["eventKeys.isInterruption"],
        indentationLevel: 0,
        apiParameters: { calculateInterruptionStats: true, calculateOutputStats: true, calculateTimeAndFreqStats: true },
        dataGapFilling: DataGapFilling.Zero,
        // Interruption is not labeled for planning data and we should not be hitting the deviation api.
        timeperiodApi: TimeperiodApis.Event,
        allowedComparisons: removeBestProcessForTime([KpiComparisons.None, KpiComparisons.BestProcesses]),
        allowedStatistics: allStats,
        isLessBetter: true,
        caseCustomKpis: getCaseCustomKpiForTimeType("interruption"),
        caseStatisticsPath: "interruptionTimeStatistics.sum",
        productStatisticsPath: getProductPathForTimeType("interruption"),
        productCustomKpis: getProductCustomKpiForTimeType("interruption"),

        eventOverTimeStatisticsPath: getEventOverTimePathForTimeType("interruption"),
        eventOverTimeCustomKpis: getEventOverTimeCustomKpisForTimeType("interruption"),

        equipmentNodeCustomKpis: getEventOverTimeCustomKpisForTimeType("interruption", true),

        equipmentNodeStatsPath: getEquipmentNodeStatsPath("interruption"),

        // Definitions for the value stream section
        nodeStatisticsPath: getNodePathForTimeType("interruption"),
        nodeCustomKpis: getNodeCustomKpiForTimeType("interruption"),

        nodeOverTimeCustomKpis: getNodeOverTimeCustomKpisForTimeType("interruption"),
        nodeOverTimeStatisticsPath: (settings) => getNodeOverTimePath(settings, "interruption"),
        edgeStatisticsPath: "timeStatistics",
        isWipIncluded: (session) => !!session.project?.eventKeys?.isWip,
    }
);

/**
 * Unit down time
 * KPI ID 1003
 */
kpiMap.set(
    KpiTypes.UnitDownTime,
    {
        label: "common.unitDownTime",
        unit: Formatter.units.durationShort,
        allowedStatistics: allStats,
        isQuantityDependent: false,
        allowedQuantities: noAllowedKpiQuantities,
        dataGapFilling: DataGapFilling.Zero,

        spotlightId: "Kpi-UnitDownTime",
        requiredEventKeys: ["uploads.shiftCalendar.columnMapping.machine"],
        apiParameters: { calculateInterruptionStats: true, calculateOutputStats: true, calculateTimeAndFreqStats: true },

        allowedComparisons: removeBestProcessForTime([KpiComparisons.None, KpiComparisons.BestProcesses]),
        isLessBetter: true,

        equipmentNodeStatsPath: { sum: "downTimeStatistics.total" },
        equipmentOverTimeStatisticsPath: { sum: "downTimeStatistics.total" }
    }
);

/**
 * The failure time (renamed to unit delay time) is the time during which an failure takes place.
 * It is not explicitly mentioned in the ISO and may be deprecated.
 * It can be aggregated for cases, products and over time.
 * Also it it is calculated for nodes.
 * And it is the sum of (technical losses, organizational losses, process losses and quality losses).
 * KPI ID 1007
 */
const unitDelayTimeEventKeys = ["isFailure", "isOtherTechnicalLosses", "isUnplannedPreventiveMaintenance",
    "isCorrectiveMaintenance", "isNonActiveMaintenance", "isMaterialShortage", "isDeliveryProblem",
    "isAuthorizationShortage", "isEmployeeShortage", "isOtherOrganizationalLosses",
    "isProcessLosses", "isQualityLosses"];
kpiMap.set(
    KpiTypes.FailureTime,
    {
        label: "common.unitDelayTime",
        labelPlan: "common.plannedUnitDelayTime",
        unit: unitForTimes,
        isQuantityDependent: true,
        allowedQuantities: (session: SessionType) =>
            quantityHelper(session, QuantityType.CaseYield, QuantityType.Yield),
        spotlightId: "Kpi-FailureTime",
        requiredEventKeys: (session: SessionType) => {
            let result: string[] = [];

            result = unitDelayTimeEventKeys.filter(eventKey => get(session.project?.eventKeys, eventKey) !== undefined);
            return result.length === 0 ? ["kpiNotAllowed"] : result.map(e => `eventKeys.${e}`);
        },
        indentationLevel: 0,
        apiParameters: { calculateFailureStats: true, calculateOutputStats: true, calculateTimeAndFreqStats: true },
        dataGapFilling: DataGapFilling.Zero,
        allowedComparisons: (session: SessionType, settings: SettingsType) => {
            if (settings.kpi.aggregation === AggregationTypes.Time)
                return [KpiComparisons.None];

            const hasPlanning = session.project?.uploadIdPlan !== undefined && session.project?.eventKeysPlan !== undefined;
            const hasRoutings = session.project?.uploads?.routings !== undefined;
            const hasUtilizationRate = session.project?.uploads?.routings?.columnMapping.utilizationRate !== undefined;

            if ((hasRoutings && hasUtilizationRate) ||
                (hasPlanning && unitDelayTimeEventKeys.some(eventKey => get(session.project?.eventKeysPlan, eventKey) !== undefined)))
                return [KpiComparisons.None, KpiComparisons.BestProcesses, KpiComparisons.Planning];

            return [KpiComparisons.None, KpiComparisons.BestProcesses];
        },
        allowedStatistics: allStats,
        isLessBetter: true,
        timeperiodApi: TimeperiodApis.Event,
        caseCustomKpis: getCaseCustomKpiForTimeType("failure"),
        caseStatisticsPath: "failureTimeStatistics.sum",
        productStatisticsPath: getProductPathForTimeType("failure"),
        productCustomKpis: getProductCustomKpiForTimeType("failure"),

        equipmentNodeStatsPath: getEquipmentNodeStatsPath("failure"),

        // Definitions for the value stream section
        nodeStatisticsPath: getNodePathForTimeType("failure"),
        nodeCustomKpis: getNodeCustomKpiForTimeType("failure"),

        nodeOverTimeCustomKpis: getNodeOverTimeCustomKpisForTimeType("failure"),
        nodeOverTimeStatisticsPath: (settings) => getNodeOverTimePath(settings, "failure"),

        eventOverTimeStatisticsPath: getEventOverTimePathForTimeType("failure"),
        eventOverTimeCustomKpis: getEventOverTimeCustomKpisForTimeType("failure"),

        equipmentNodeCustomKpis: getEventOverTimeCustomKpisForTimeType("failure", true),

        edgeStatisticsPath: "timeStatistics",
        equipmentOverTimeStatisticsPath: { sum: "failureTimeStatistics.total" },
        isWipIncluded: (session) => !!session.project?.eventKeys?.isWip,
    }
);

/**
 * The overall equipment effectiveness (OEE) is a measure of how well a manufacturing operation is utilized.
 * It is fake for now and should be replaced by the real OEE.
 * 3101
 */
kpiMap.set(
    KpiTypes.OverallEquipmentEffectiveness,
    {
        label: "common.OEE",
        unit: Formatter.units.percent,
        isQuantityDependent: true,
        allowedQuantities: (session: SessionType) =>
            quantityHelper(session, QuantityType.CaseYield, QuantityType.Yield),
        spotlightId: "Kpi-OverallEquipmentEffectiveness",
        isEquipmentStatsKpi: true,
        dataGapFilling: DataGapFilling.Zero,
        apiParameters: { calculateProductionStats: true, calculateBusyStats: true, calculateOutputStats: true, calculateTimeAndFreqStats: true },
        allowedComparisons: [KpiComparisons.None, KpiComparisons.Planning],
        allowedStatistics: [StatisticTypes.Mean],
        requiredEventKeys: (session: SessionType) => {
            // If it is a demo project then we dont care about the requiredEventKeys, display the oee anyway cause we have a special
            // kpi definition that is used for the demo project (so it is NOT the real OEE)
            if (session.project?.settings?.isDemoProject)
                return [];

            return ["uploads.routings.columnMapping.routingId", "uploads.shiftCalendar.columnMapping.startTime",
                "uploads.shiftCalendar.columnMapping.endTime", "uploads.shiftCalendar.columnMapping.machine"];
        },
        isLessBetter: false,

        equipmentNodeStatsPath: { mean: "customKpis.oee.value" },

        equipmentOverTimeStatisticsPath: { mean: "customKpis.oee.value" },

        equipmentNodeCustomKpis: (session, settings) => {
            if (settings.quantity === undefined)
                return undefined;

            if (session.project?.settings?.isDemoProject)
                return [{
                    id: "oee",
                    // FIXME: This is a hack for the demo and NOT the real OEE
                    definition: `0.71 * (equipment.yield${capitalize(settings.quantity)}Statistics.sum / equipment.output${capitalize(settings.quantity)}Statistics.sum) * (equipment.productionTimeStatistics.total / equipment.busyTimeStatistics.total)`,
                    target: "equipment"
                }];

            return [{
                id: "oee",
                definition: `(equipment.productionTimeStatistics.total / planned.equipment.busyTimeStatistics.total) * (planned.equipment.productionTimeStatistics.total / equipment.productionTimeStatistics.total) * (equipment.yield${capitalize(settings.quantity)}Statistics.sum / equipment.output${capitalize(settings.quantity)}Statistics.sum)`,
                target: "equipment"
            }];
        },
    }
);


/**
 * Availability
 * 3113
 */
kpiMap.set(
    KpiTypes.Availability,
    {
        label: "common.availability",
        unit: Formatter.units.percent,
        indentationLevel: 0,
        isQuantityDependent: false,
        allowedQuantities: noAllowedKpiQuantities,
        dataGapFilling: DataGapFilling.Zero,
        isEquipmentStatsKpi: true,
        spotlightId: "Kpi-Availability",
        apiParameters: { calculateProductionStats: true, calculateBusyStats: true, calculateOutputStats: true, calculateTimeAndFreqStats: true },
        allowedComparisons: [KpiComparisons.None, KpiComparisons.Planning],
        allowedStatistics: [StatisticTypes.Mean],
        requiredEventKeys: (session: SessionType) => {
            // If it is a demo project then we dont care about the requiredEventKeys, display the oee anyway cause we have a special
            // kpi definition that is used for the demo project (so it is NOT the real OEE)
            if (session.project?.settings?.isDemoProject)
                return [];
            return ["uploads.shiftCalendar.columnMapping.startTime", "uploads.shiftCalendar.columnMapping.endTime", "uploads.shiftCalendar.columnMapping.machine"];
        },
        isLessBetter: false,

        equipmentNodeStatsPath: { mean: "customKpis.availability.value" },

        equipmentNodeCustomKpis: (session) => {
            if (session.project?.settings?.isDemoProject)
                return [{
                    id: "availability",
                    // FIXME: This is a hack for the demo and NOT the real availability
                    definition: "(equipment.productionTimeStatistics.total / equipment.busyTimeStatistics.total)",
                    target: "equipment"
                }];
            return [{
                id: "availability",
                definition: "equipment.productionTimeStatistics.total / planned.equipment.busyTimeStatistics.total",
                target: "equipment"
            }];
        },

        equipmentOverTimeStatisticsPath: { mean: "customKpis.availability.value" },
    }
);

/**
 * Effectiveness
 * 3114
 */
kpiMap.set(
    KpiTypes.Effectiveness,
    {
        label: "common.effectiveness",
        indentationLevel: 0,
        unit: Formatter.units.percent,
        isQuantityDependent: false,
        allowedQuantities: noAllowedKpiQuantities,
        dataGapFilling: DataGapFilling.Zero,
        isEquipmentStatsKpi: true,
        spotlightId: "Kpi-Effectiveness",
        apiParameters: { calculateProductionStats: true, calculateBusyStats: true, calculateOutputStats: true, calculateTimeAndFreqStats: true },
        allowedComparisons: [KpiComparisons.None, KpiComparisons.Planning],
        allowedStatistics: [StatisticTypes.Mean],
        requiredEventKeys: (session: SessionType) => {
            // If it is a demo project then we dont care about the requiredEventKeys, display the oee anyway cause we have a special
            // kpi definition that is used for the demo project (so it is NOT the real OEE)
            if (session.project?.settings?.isDemoProject)
                return [];
            return ["uploads.routings.columnMapping.routingId", "uploads.shiftCalendar.columnMapping.startTime",
                "uploads.shiftCalendar.columnMapping.endTime", "uploads.shiftCalendar.columnMapping.machine"];
        },
        isLessBetter: false,

        equipmentNodeStatsPath: { mean: "customKpis.effectiveness.value" },

        equipmentNodeCustomKpis: (session) => {
            if (session.project?.settings?.isDemoProject)
                return [{
                    id: "effectiveness",
                    // FIXME: This is a hack for the demo and NOT the real effectiveness
                    definition: "0.71",
                    target: "equipment"
                }];
            return [{
                id: "effectiveness",
                definition: "planned.equipment.productionTimeStatistics.total / equipment.productionTimeStatistics.total",
                target: "equipment"
            }];
        },

        equipmentOverTimeStatisticsPath: { mean: "customKpis.effectiveness.value" },

    }
);

/**
 * Quality rate
 * 3115
 */
kpiMap.set(
    KpiTypes.QualityRate,
    {
        label: "common.qualityRate",
        unit: Formatter.units.percent,
        indentationLevel: 0,
        isQuantityDependent: true,
        allowedQuantities: (session: SessionType) =>
            quantityHelper(session, QuantityType.CaseYield, QuantityType.Yield),
        isEquipmentStatsKpi: true,
        spotlightId: "Kpi-QualityRate",
        apiParameters: { calculateProductionStats: true, calculateBusyStats: true, calculateOutputStats: true, calculateTimeAndFreqStats: true },
        allowedComparisons: [KpiComparisons.None, KpiComparisons.Planning],
        allowedStatistics: [StatisticTypes.Mean],
        isLessBetter: false,
        dataGapFilling: DataGapFilling.Zero,

        equipmentNodeStatsPath: { mean: "customKpis.qualityRate.value" },

        equipmentNodeCustomKpis: (_session, settings) => {
            if (settings.quantity === undefined)
                return undefined;
            return [{
                id: "qualityRate",
                definition: `equipment.yield${capitalize(settings.quantity)}Statistics.sum / equipment.output${capitalize(settings.quantity)}Statistics.sum`,
                target: "equipment"
            }];
        },
        equipmentOverTimeStatisticsPath: { mean: "customKpis.qualityRate.value" },
    }
);

/**
 * Utilization rate is the ratio of busy time relative to planned busy time.
 * It is currently only used to forward the definition to the bottleneck.
 */
kpiMap.set(
    KpiTypes.UtilizationRate,
    {
        label: "common.utilizationRate",
        unit: Formatter.units.percent,
        isQuantityDependent: false,
        allowedQuantities: noAllowedKpiQuantities,
        dataGapFilling: DataGapFilling.Zero,
        isEquipmentStatsKpi: true,
        spotlightId: "Kpi-UtilizationRate",
        apiParameters: { calculateBusyStats: true, calculateTimeAndFreqStats: true, calculatePlanned: true },
        allowedComparisons: [KpiComparisons.None, KpiComparisons.Planning],
        allowedStatistics: [StatisticTypes.Mean],
        requiredEventKeys: ["uploads.shiftCalendar.columnMapping.startTime", "uploads.shiftCalendar.columnMapping.endTime", "uploads.shiftCalendar.columnMapping.machine"],
        isLessBetter: false,

        equipmentNodeStatsPath: { mean: "customKpis.utilizationRate.value" },

        equipmentNodeCustomKpis: [{
            id: "utilizationRate",
            definition: "equipment.busyTimeStatistics.total / planned.equipment.busyTimeStatistics.total",
            target: "equipment"
        }],

        equipmentOverTimeStatisticsPath: { mean: "customKpis.utilizationRate.value" },
    }
);



/**
 * The queuing time is the time where a component is transitioning between workplaces (this can be storage time or transport time).
 * In the ISO it is named actual queuing time (AQT).
 * It can be aggregated for cases, products and over time.
 * Also it it is calculated for nodes.
 *
 * KPI ID 1004
 */
kpiMap.set(
    KpiTypes.QueuingTime,
    {
        label: "common.transitionTime",
        labelPlan: "common.plannedTransition",
        unit: Formatter.units.durationShort,
        spotlightId: "Kpi-QueuingTime",
        apiParameters: { calculateTimeAndFreqStats: true },
        allowedQuantities: noAllowedKpiQuantities,
        isQuantityDependent: false,
        dataGapFilling: DataGapFilling.Zero,
        allowedStatistics: allStats,
        allowedComparisons: noPlanningForRoutings,
        isLessBetter: true,
        productStatisticsPath: "caseTransitionTimeStatistics",
        caseStatisticsPath: "transitionTimeStatistics.sum",
        edgeStatisticsPath: "timeStatistics",
    }
);

/**
 * The order count is just the number of orders.
 * It can be aggregated for products and over time.
 *
 * KPI ID 3001
 */
kpiMap.set(
    KpiTypes.OrderCount,
    {
        label: "common.caseCount",
        unit: Formatter.units.numberShort,
        isQuantityDependent: false,
        allowedQuantities: noAllowedKpiQuantities,
        dataGapFilling: DataGapFilling.Zero,
        spotlightId: "Kpi-OrderCount",
        timeperiodApi: TimeperiodApis.Case,
        apiParameters: {
            calculateTimeAndFreqStats: true,
            calculateOutputStats: true,
        },
        allowedComparisons: [KpiComparisons.None],
        allowedStatistics: [StatisticTypes.Sum],
        isLessBetter: undefined,
        isWipIncluded: (session) => {
            return !!session.project?.eventKeys?.isWip;
        },
        // For cases we always display a number of one.
        // This path here is just used for sorting purposes.
        caseStatisticsPath: (settings) => `caseOutput${capitalize(settings.quantity)}`,
        productStatisticsPath: { sum: "count" },
    }
);

/**
 * The product count are the number of different products that go over a node.
 *
 * KPI ID
 */
kpiMap.set(
    KpiTypes.ProductCount,
    {
        label: "common.productCount",
        unit: Formatter.units.numberShort,
        isQuantityDependent: false,
        allowedQuantities: noAllowedKpiQuantities,
        spotlightId: "Kpi-ProductCount",

        // TODO: IS THIS CORRECT?
        timeperiodApi: TimeperiodApis.Case,

        apiParameters: { calculateTimeAndFreqStats: true, calculateActivityValues: true },
        dataGapFilling: DataGapFilling.Zero,
        allowedComparisons: [KpiComparisons.None],
        allowedStatistics: [StatisticTypes.Sum],
        isLessBetter: undefined,
        nodeStatisticsPath: { sum: "activityValues.product.nUnique" },

        nodeOverTimeStatisticsPath: { sum: "activityValues.product.nUnique" },

    }
);

/**
 * The frequency / production passes is the number of times that a node/edge is passed.
 * It can be displayed for nodes and edges.
 *
 * KPI ID 3002
 */
kpiMap.set(
    KpiTypes.Frequency,
    {
        label: "common.passCount",
        unit: Formatter.units.numberShort,
        isQuantityDependent: false,
        allowedQuantities: noAllowedKpiQuantities,
        spotlightId: "Kpi-Frequency",
        allowedComparisons: [KpiComparisons.None, KpiComparisons.Planning],
        allowedStatistics: [StatisticTypes.Sum],

        isLessBetter: true,

        apiParameters: { calculateTimeAndFreqStats: true, useActivityPasses: true },
        dataGapFilling: DataGapFilling.Zero,

        nodeStatisticsPath: (settings) => {
            if (groupingKeysPassCompatibleGroups.includes(settings.groupingKey))
                return "activityPassFrequencyStatistics";
            return "frequencyStatistics";
        },

        nodeOverTimeStatisticsPath: (settings) => {
            if (groupingKeysPassCompatibleGroups.includes(settings.groupingKey))
                return "activityPassFrequencyStatistics." + settings.kpi.statistic.toString();
            return "frequencyStatistics." + settings.kpi.statistic.toString();
        },

        edgeStatisticsPath: "frequencyStatistics",
    }
);

/**
 * The produced quantity is the total produced quantity (scrap + good quantity).
 * It can be aggregated for products, orders, over time and nodes.
 *
 * KPI ID 3009
 */
kpiMap.set(
    KpiTypes.ProducedQuantity,
    {
        label: "output.values",
        labelPlan: "output.plannedValues",
        unit: getQuantityUnitFromSettings,
        isQuantityDependent: true,
        // Output is inferred from yield
        allowedQuantities: (session: SessionType) =>
            quantityHelper(session, QuantityType.CaseYield, QuantityType.Yield),

        spotlightId: "Kpi-ProducedQuantity",
        apiParameters: { calculateOutputStats: true, calculateTimeAndFreqStats: true },
        dataGapFilling: DataGapFilling.Zero,
        allowedStatistics: allStats,
        allowedComparisons: (session, settings) => {
            if (settings.kpi.aggregation === AggregationTypes.Time)
                if (session.project?.uploads?.routings !== undefined &&
                    !session.project?.uploads?.cases?.columnMapping[`plannedYield${capitalize(settings.quantity)}`])
                    return [KpiComparisons.None];

            return [KpiComparisons.None, KpiComparisons.Planning, KpiComparisons.BestProcesses];
        },
        isLessBetter: false,
        productStatisticsPath: (settings) => `caseOutput${capitalize(settings.quantity)}Statistics`,
        caseStatisticsPath: (settings) => `caseOutput${capitalize(settings.quantity)}`,

        equipmentNodeStatsPath: (settings) => `output${capitalize(settings.quantity)}Statistics`,

        // Definitions for the value stream section
        nodeStatisticsPath: (settings) => `output${capitalize(settings.quantity)}Statistics`,
        equipmentOverTimeStatisticsPath: (settings) => {
            return {
                sum: `output${capitalize(settings.quantity)}Statistics.sum`
            };
        },
        nodeOverTimeStatisticsPath: (settings) => {
            return {
                mean: `output${capitalize(settings.quantity)}Statistics.mean`,
                sum: `output${capitalize(settings.quantity)}Statistics.sum`,
                median: `output${capitalize(settings.quantity)}Statistics.median`,
            };
        },
    }
);

/**
 * The good quantity is only the good quantity.
 * It can be aggregated for products, orders and over time.
 *
 * KPI ID 3005
 */
kpiMap.set(
    KpiTypes.GoodQuantity,
    {
        label: "common.yield",
        labelPlan: "common.plannedYield",
        unit: getQuantityUnitFromSettings,
        isQuantityDependent: true,
        allowedQuantities: (session: SessionType) =>
            quantityHelper(session, QuantityType.CaseYield, QuantityType.Yield),

        spotlightId: "Kpi-GoodQuantity",
        apiParameters: { calculateOutputStats: true, calculateTimeAndFreqStats: true },
        dataGapFilling: DataGapFilling.Zero,
        timeperiodApi: TimeperiodApis.Case,
        allowedStatistics: allStats,
        allowedComparisons: (session, settings) => {
            if (settings.kpi.aggregation === AggregationTypes.Time)
                if (session.project?.uploads?.routings !== undefined &&
                    !session.project?.uploads?.cases?.columnMapping[`plannedYield${capitalize(settings.quantity)}`])
                    return [KpiComparisons.None];

            return [KpiComparisons.None, KpiComparisons.Planning, KpiComparisons.BestProcesses];
        },
        isLessBetter: false,
        productStatisticsPath: (settings) => `caseYield${capitalize(settings.quantity)}Statistics`,
        caseStatisticsPath: (settings) => `caseYield${capitalize(settings.quantity)}`,

        // Definitions for the value stream section
        nodeStatisticsPath: (settings) => `yield${capitalize(settings.quantity)}Statistics`,

        edgeStatisticsPath: (settings) => { return `yield${capitalize(settings.quantity)}Statistics`; },

        nodeOverTimeStatisticsPath: (settings) => `yield${capitalize(settings.quantity)}Statistics.${settings.kpi.statistic.toString()}`,
        equipmentOverTimeStatisticsPath: (settings) => {
            return {
                sum: `yield${capitalize(settings.quantity)}Statistics.sum`
            };
        },
    }
);

/**
 * The throughput rate is the produced quantity (output) per throughput time.
 * It can be aggregated for products, orders and over time.
 *
 * KPI ID 3107
 */
kpiMap.set(
    KpiTypes.ThroughputRate,
    {
        label: "common.throughput",
        labelPlan: "common.plannedThroughput",
        unit: getQuantityFlowUnitFromSettings,
        isQuantityDependent: true,
        // Output is inferred from yield
        allowedQuantities: (session: SessionType) =>
            quantityHelper(session, QuantityType.CaseYield, QuantityType.Yield),

        spotlightId: "Kpi-ThroughputRate",
        apiParameters: { calculateOutputStats: true, calculateTimeAndFreqStats: true, calculateActivityValues: true },
        dataGapFilling: DataGapFilling.Zero,
        allowedStatistics: statsWithoutSum,
        allowedComparisons: noPlanningForRoutings,
        isLessBetter: false,
        caseStatisticsPath: (settings) => `customKpis.throughput${capitalize(settings.quantity)}.value`,
        productStatisticsPath: (settings) => {
            return {
                mean: `customKpis.throughput${capitalize(settings.quantity)}Mean.value`,
                median: `customKpis.throughput${capitalize(settings.quantity)}.statistics.median`,
                variance: `customKpis.throughput${capitalize(settings.quantity)}.statistics`,
            };
        },
        productCustomKpis: (settings) => {
            const aggregation = settings.kpi.aggregation === AggregationTypes.Time ? "timeperiods" : "products";
            return [
                // We calculate the throughput rate as the total output quantity divided by the total throughput time.
                // In the backend the throughput was calculated only based on yield before so we need to use a custom kpi here.
                {
                    id: `throughput${capitalize(settings.quantity)}`,
                    definition: `cases.caseOutput${capitalize(settings.quantity)} / cases.duration`,
                    statistics: { aggs: [AggTypes.P25, AggTypes.P75, AggTypes.Mean, AggTypes.Median, AggTypes.Min, AggTypes.Max] },
                    target: aggregation,
                },
                // For the mean we need to make use of a custom kpi.
                // The reason is that we would like to calculate the total output quantity divided by the total throughput time.
                // This leads to the weighted mean of the throughput.
                {
                    id: `throughput${capitalize(settings.quantity)}Mean`,
                    definition: `${aggregation}.caseOutput${capitalize(settings.quantity)}Statistics.sum / ${aggregation}.durationStatistics.sum`,
                    target: aggregation,
                }];
        },
        caseCustomKpis: (settings) => {
            return [{
                id: `throughput${capitalize(settings.quantity)}`,
                definition: `cases.caseOutput${capitalize(settings.quantity)} / cases.duration`,
                target: "cases",
            }];
        },

        // Definitions for the value stream section
        nodeCustomKpis: (settings) => {
            if (settings.quantity === undefined)
                return undefined;
            return [{
                id: "throughputMean",
                definition: `graph.nodes.output${capitalize(settings.quantity)}Statistics.sum / graph.nodes.activityPassTimeStatistics.sum * graph.nodes.activityValues.machine.nUnique`,
                target: "graph.nodes"
            }, {
                id: "throughputStatistics",
                definition: `events.output${capitalize(settings.quantity)} / events.duration`,
                statistics: { aggs: [AggTypes.Min, AggTypes.Max] },
                target: "graph.nodes"
            }];
        },
        edgeCustomKpis: (settings) => {
            if (settings.quantity === undefined)
                return undefined;
            return [{
                id: "throughputMeanEdge",
                definition: `graph.edges.yield${capitalize(settings.quantity)}Statistics.sum / graph.edges.timeStatistics.sum`,
                target: "graph.edges"
            }, {
                id: "throughputStatisticsEdge",
                definition: `events.fromYield${capitalize(settings.quantity)} / events.duration`,
                statistics: { aggs: [AggTypes.Min, AggTypes.Median, AggTypes.Max] },
                target: "graph.edges",
            }];
        },
        nodeStatisticsPath: {
            mean: "customKpis.throughputMean.value",
            variance: "customKpis.throughputStatistics.statistics",
        },
        edgeStatisticsPath: {
            mean: "customKpis.throughputMeanEdge.value",
            variance: "customKpis.throughputStatisticsEdge.statistics"
        },
    }
);

/**
 * The work in process inventory is the quantity of order quantity that is at the same time worked on on the shop floor.
 * It is the sum over all orders and the average is taken over the timeperiod.
 * It can be aggregated for products, orders and over time.
 *
 * KPI ID 8002
 */
kpiMap.set(
    KpiTypes.WorkInProcessInventory,
    {
        label: "output.goodsInProcess",
        dataGapFilling: DataGapFilling.Zero,
        labelPlan: "output.plannedGoodsInProcess",
        unit: getQuantityUnitFromSettings,
        isQuantityDependent: true,
        isWipIncluded: (session) => {
            return !!session.project?.eventKeys?.isWip;
        },
        allowedQuantities: (session: SessionType) =>
            quantityHelper(session, QuantityType.CaseYield, QuantityType.Yield),

        timeperiodApi: TimeperiodApis.Case,

        spotlightId: "Kpi-WorkInProcessInventory",
        allowedStatistics: [StatisticTypes.Mean],
        apiParameters: { calculateOutputStats: true, calculateTimeAndFreqStats: true },
        allowedComparisons: (session) => noPlanningForRoutings(session).filter(c => c !== KpiComparisons.BestProcesses),
        isLessBetter: true,
        productStatisticsPath: (settings) => {
            return {
                mean: settings.kpi.aggregation === AggregationTypes.Time ?
                    `kpis.timeperiodAverageYieldStock${capitalize(settings.quantity)}` :
                    `kpis.productAverageYieldStock${capitalize(settings.quantity)}`
            };
        },
        caseStatisticsPath: (settings) => `kpis.caseAverageYieldStock${capitalize(settings.quantity)}`,
        caseCustomKpis: (settings) => {
            return [{
                id: `caseAverageYieldStock${capitalize(settings.quantity)}`,
                definition: `(cases.caseYield${capitalize(settings.quantity)} * cases.duration) / log.duration`,
                target: "cases",
            }];
        },
        logStatisticsPath: (settings) => {
            return {
                mean: `customKpis.caseAverageYieldStock${capitalize(settings.quantity)}.statistics.sum`
            };
        },

        // Definitions for the value stream section
        nodeStatisticsPath: (settings) => { return { mean: `kpis.averageYieldStock${capitalize(settings.quantity)}` }; },
        edgeStatisticsPath: (settings) => { return { mean: `kpis.averageYieldStock${capitalize(settings.quantity)}` }; },
        edgeOverTimeStatisticsPath: (settings) => { return { mean: `yieldStock${capitalize(settings.quantity)}Statistics.mean` }; },

        // TODO: Add node over time
    }
);

/**
 * The order backlog is the average amount of active transitions with respect to the timeperiod.
 *
 * KPI ID 8010
 */
kpiMap.set(
    KpiTypes.OrderBacklog,
    {
        label: "common.orderBacklog",
        dataGapFilling: DataGapFilling.Zero,
        unit: Formatter.units.numberShort,
        isQuantityDependent: false,
        allowedQuantities: noAllowedKpiQuantities,

        spotlightId: "Kpi-OrderBacklog",
        allowedStatistics: [StatisticTypes.Mean],
        apiParameters: { calculateTimeAndFreqStats: true },
        allowedComparisons: [KpiComparisons.None],
        isLessBetter: true,
        caseCustomKpis: [{
            id: "caseOrderBacklog",
            definition: "cases.duration / log.duration",
            target: "cases",
        }]
        ,
        logStatisticsPath: {
            mean: "customKpis.caseOrderBacklog.statistics.sum"
        },

        // Definitions for the value stream section
        nodeStatisticsPath: { mean: "customKpis.activeTransitions.value" },
        edgeStatisticsPath: { mean: "customKpis.activeTransitions.value" },
        edgeCustomKpis: [{
            id: "activeTransitions",
            definition: "graph.edges.timeStatistics.sum / log.duration ",
            target: "graph.edges"
        }],
        edgeOverTimeStatisticsPath: "activeTransitions.mean",
        // TODO: Add node over time
    }
);

function getComparisonsScrapRouting(session: SessionType) {
    const hasRoutings = session.project?.uploads?.routings !== undefined;
    const hasScrapRatio = session.project?.uploads?.routings?.columnMapping.scrapRatio !== undefined;

    if (hasRoutings && !hasScrapRatio)
        return [KpiComparisons.None, KpiComparisons.BestProcesses];

    return [KpiComparisons.None, KpiComparisons.BestProcesses, KpiComparisons.Planning];
}

/**
 * The scrap quantity is the total scrap that was produced for an order.
 * It can be aggregated for products, orders and over time.
 *
 * KPI ID 4001 & 4002
 */
kpiMap.set(
    KpiTypes.ScrapQuantity,
    {
        label: "quality.values",
        labelPlan: "quality.plannedValues",
        unit: getQuantityUnitFromSettings,
        isQuantityDependent: true,
        isWipIncluded(session) {
            return !!session.project?.eventKeys?.isWip;
        },
        allowedQuantities: (session: SessionType) => {
            // Here's a discussion: In case, we don't have caseScrap, the backend infers that
            // from regular scrap. So as a fallback, that would be okay, too. This is how it
            // currently works (because Schoeller), but it doesn't necessary have to stay
            // like this!
            const scrapCaseScrap = union(
                getAssignedQuantities(session.project?.eventKeys, QuantityType.CaseScrap, false).map(q => q.baseQuantity),
                getAssignedQuantities(session.project?.eventKeys, QuantityType.Scrap, false).map(q => q.baseQuantity),
            );

            const scrapCaseScrapDeviation = union(
                getAssignedQuantitiesDeviationHelper(session.project?.eventKeys, session.project?.eventKeysPlan, QuantityType.CaseScrap),
                getAssignedQuantitiesDeviationHelper(session.project?.eventKeys, session.project?.eventKeysPlan, QuantityType.Scrap),
            );

            return {
                actual: {
                    case: scrapCaseScrap,
                    node: scrapCaseScrap,
                },
                plan: {
                    case: scrapCaseScrapDeviation,
                    node: scrapCaseScrapDeviation,
                }
            };
        },
        spotlightId: "Kpi-ScrapQuantity",
        apiParameters: { calculateOutputStats: true, calculateTimeAndFreqStats: true, },
        dataGapFilling: DataGapFilling.Zero,
        timeperiodApi: TimeperiodApis.Event,
        allowedStatistics: allStats,
        allowedComparisons: (session, settings) => {
            // event endpoint can only return planning if we have routings
            const { hasRoutings } = getPlanningState(session);
            const canHandlePlanningForTime = (c: KpiComparisons) => c !== KpiComparisons.Planning || settings.kpi.aggregation != AggregationTypes.Time || hasRoutings;
            return removeBestProcessForTime(getComparisonsScrapRouting(session))(session, settings).filter(canHandlePlanningForTime);
        },
        isLessBetter: true,
        productStatisticsPath: (settings) => `caseScrap${capitalize(settings.quantity)}Statistics`,
        eventOverTimeStatisticsPath: (_, settings) => `scrap${capitalize(settings.quantity)}Statistics`,
        caseStatisticsPath: (settings) => `caseScrap${capitalize(settings.quantity)}`,

        // Definitions for the value stream section
        nodeStatisticsPath: (settings) => `scrap${capitalize(settings.quantity)}Statistics`,
        nodeOverTimeStatisticsPath: (settings) => `scrap${capitalize(settings.quantity)}Statistics.${settings.kpi.statistic.toString()}`,
    }
);

/**
 * The scrap ratio is the ratio of scrap and total output.
 * It can be aggregated for products, orders and over time.
 *
 * KPI ID 4004 & 4005
 */
kpiMap.set(
    KpiTypes.ScrapRatio,
    {
        label: "quality.quota",
        labelPlan: "quality.plannedRelativeScrap",
        unit: Formatter.units.percent,
        isQuantityDependent: true,

        // Output is inferred from yield
        allowedQuantities: (session: SessionType) => {
            // show a quantity if for that quantity the following is labelled: case yield AND (scrap OR case scrap)
            const [
                caseYieldActual, caseScrapActual, yieldActual, scrapActual,
                caseYieldPlan, caseScrapPlan, yieldPlan, scrapPlan
            ] = flatMap([false, true].map(isPlan => {
                return [QuantityType.CaseYield, QuantityType.CaseScrap, QuantityType.Yield, QuantityType.Scrap].map(quantity => {
                    return getAssignedQuantities(
                        isPlan ? session.project?.eventKeysPlan : session.project?.eventKeys,
                        quantity,
                        false).map(q => q.baseQuantity);
                });
            }));

            return {
                actual: {
                    case: intersection(caseYieldActual, union(caseScrapActual, scrapActual)),
                    node: intersection(yieldActual, scrapActual),
                },
                plan: {
                    case: intersection(caseYieldActual, caseYieldPlan, union(
                        intersection(caseScrapActual, caseScrapPlan),
                        intersection(scrapActual, scrapPlan))
                    ),
                    node: intersection(yieldActual, yieldPlan, intersection(scrapActual, scrapPlan)),
                }
            };
        },
        spotlightId: "Kpi-ScrapRatio",
        apiParameters: { calculateOutputStats: true, calculateTimeAndFreqStats: true, },
        dataGapFilling: DataGapFilling.Zero,
        allowedStatistics: statsWithoutSum,
        allowedComparisons: (session, settings) => {
            return removeBestProcessForTime(getComparisonsScrapRouting(session))(session, settings);
        },
        timeperiodApi: TimeperiodApis.Case,
        isLessBetter: true,
        caseStatisticsPath: (settings) => `kpis.relativeCaseScrap${capitalize(settings.quantity)}`,
        productStatisticsPath: (settings) => {
            return {
                median: `relativeCaseScrap${capitalize(settings.quantity)}Statistics.median`,
                variance: `relativeCaseScrap${capitalize(settings.quantity)}Statistics`,
                mean: `customKpis.relativeCaseScrap${capitalize(settings.quantity)}.value`
            };
        },
        // The mean is calculated based on weighted values here.
        productCustomKpis: (settings) => {
            const aggregation = settings.kpi.aggregation === AggregationTypes.Time ? "timeperiods" : "products";
            return [{
                id: `relativeCaseScrap${capitalize(settings.quantity)}`,
                definition: `${aggregation}.caseScrap${capitalize(settings.quantity)}Statistics.sum / ${aggregation}.caseOutput${capitalize(settings.quantity)}Statistics.sum`,
                target: aggregation,
            }];
        },
        equipmentOverTimeStatisticsPath: { mean: "customKpis.scrapRatio.value" },

        // Definitions for the value stream section
        nodeCustomKpis: (settings) => {
            if (settings.quantity === undefined)
                return undefined;
            return [{
                id: "scrapRatioMean",
                definition: `graph.nodes.scrap${capitalize(settings.quantity)}Statistics.sum / graph.nodes.output${capitalize(settings.quantity)}Statistics.sum `,
                target: "graph.nodes"
            }, {
                id: "scrapRatioStatistics",
                definition: `events.scrap${capitalize(settings.quantity)} / events.output${capitalize(settings.quantity)}`,
                statistics: { aggs: [AggTypes.Min, AggTypes.Max] },
                target: "graph.nodes"
            }];
        },
        nodeStatisticsPath: {
            mean: "customKpis.scrapRatioMean.value",
            variance: "customKpis.scrapRatioStatistics.statistics",
        },

        nodeOverTimeCustomKpis: (_, settings) => {
            if (settings.quantity === undefined)
                return undefined;
            return [{
                id: "scrapRatioMean",
                definition: `graph.nodes.scrap${capitalize(settings.quantity)}Statistics.sum / graph.nodes.output${capitalize(settings.quantity)}Statistics.sum `,
                target: "graph.nodes"
            }, {
                id: "scrapRatioStatistics",
                definition: `events.scrap${capitalize(settings.quantity)} / events.output${capitalize(settings.quantity)}`,
                statistics: { aggs: [AggTypes.Median] },
                target: "graph.nodes"
            }];
        },
        nodeOverTimeStatisticsPath: () => {
            return {
                mean: "customKpis.scrapRatioMean.value",
                median: "customKpis.scrapRatioStatistics.statistics.median"
            };
        },
        equipmentNodeStatsPath: { mean: "customKpis.scrapRatio.value" },
        equipmentNodeCustomKpis: (_session, settings) => {
            if (settings.quantity === undefined)
                return undefined;
            return [{
                id: "scrapRatio",
                definition: `equipment.scrap${capitalize(settings.quantity)}Statistics.sum / equipment.output${capitalize(settings.quantity)}Statistics.sum`,
                target: "equipment"
            }];
        },
    }
);

/**
 * The amount of carbon that is emitted.
 * It can be aggregated for products and over time.
 */
kpiMap.set(
    KpiTypes.Carbon,
    {
        label: "common.carbonEmissions",
        labelPlan: "common.plannedCarbonEmissions",
        unit: { sum: Formatter.units.weight, mean: Formatter.units.carbonPerYield },
        isQuantityDependent: true,
        allowedQuantities: (session: SessionType) =>
            quantityHelper(session, QuantityType.CaseYield, QuantityType.Yield),
        requiredEventKeys: (session) => {
            if (isOniqEmployee(session) || session.project?.settings?.isDemoProject)
                return [];
            return ["carbonMass"];
        },
        apiParameters: { calculateTimeAndFreqStats: true, calculateOutputStats: true, calculateBusyStats: true, calculateEnergyStats: true },
        dataGapFilling: DataGapFilling.Zero,
        spotlightId: "Kpi-Carbon",
        allowedStatistics: allStats,
        allowedComparisons: (session) => {
            const hasRoutings = session.project?.uploads?.routings !== undefined;
            if (hasRoutings)
                return [KpiComparisons.None, KpiComparisons.BestProcesses];

            const hasPlanning = session.project?.eventKeysPlan !== undefined;
            if (hasPlanning)
                return [KpiComparisons.None, KpiComparisons.BestProcesses, KpiComparisons.Planning];

            return [KpiComparisons.None, KpiComparisons.BestProcesses];
        },
        isLessBetter: true,
        productStatisticsPath: {
            sum: "customKpis.carbonMass.statistics.sum",
            mean: "customKpis.carbonPerYieldMean.value",
            median: "customKpis.carbonPerYield.statistics.median",
            variance: "customKpis.carbonPerYield.statistics"
        },
        productCustomKpis: (settings, session) => {
            const aggregation = settings.kpi.aggregation === AggregationTypes.Time ? "timeperiods" : "products";
            if (settings.quantity === undefined)
                return undefined;
            // When the carbon mass is not defined, we calculate it based on the busy time.
            if (session?.project?.eventKeys?.carbonMass === undefined)
                return [
                    {
                        id: "carbonMass",
                        definition: "0.002 * cases.busyTimeStatistics.sum",
                        statistics: { aggs: [AggTypes.P25, AggTypes.P75, AggTypes.Mean, AggTypes.Median, AggTypes.Min, AggTypes.Max] },
                        target: aggregation,
                    }, {
                        id: "carbonPerYield",
                        definition: `0.002 * cases.busyTimeStatistics.sum / cases.caseYield${capitalize(settings.quantity)}`,
                        statistics: { aggs: [AggTypes.P25, AggTypes.P75, AggTypes.Mean, AggTypes.Median, AggTypes.Min, AggTypes.Max] },
                        target: aggregation,
                    }, {
                        id: "carbonPerYieldMean",
                        definition: `0.002 * ${aggregation}.caseBusyTimeStatistics.sum / ${aggregation}.caseYield${capitalize(settings.quantity)}Statistics.sum`,
                        target: aggregation,
                    }];
            // In all other cases we can get it from the carbon mass.
            return [{
                id: "carbonMass",
                definition: "cases.caseCarbonMass",
                statistics: { aggs: [AggTypes.P25, AggTypes.P75, AggTypes.Mean, AggTypes.Median, AggTypes.Min, AggTypes.Max] },
                target: aggregation,
            }, {
                id: "carbonPerYield",
                definition: `cases.caseCarbonMass / cases.caseYield${capitalize(settings.quantity)}`,
                statistics: { aggs: [AggTypes.P25, AggTypes.P75, AggTypes.Mean, AggTypes.Median, AggTypes.Min, AggTypes.Max] },
                target: aggregation,
            }, {
                id: "carbonPerYieldMean",
                definition: `${aggregation}.caseCarbonMassStatistics.sum / ${aggregation}.caseYield${capitalize(settings.quantity)}Statistics.sum`,
                target: aggregation,
            }];
        },
        caseStatisticsPath: "customKpis.carbonMass.value",
        caseCustomKpis: (settings, session) => {
            // When the electricity energy is not defined, we calculate it based on the busy time.
            if (session?.project?.eventKeys?.carbonMass === undefined)
                return [{
                    id: "carbonMass",
                    definition: "0.002 * cases.busyTimeStatistics.sum",
                    target: "cases"
                }, {
                    id: "carbonPerYield",
                    definition: `0.002 * cases.busyTimeStatistics.sum / cases.caseYield${capitalize(settings.quantity)}`,
                    target: "cases"
                }];
            // In all other cases we can get it from the electricity energy.
            return [{
                id: "carbonMass",
                definition: "cases.caseCarbonMass",
                target: "cases"
            }, {
                id: "carbonPerYield",
                definition: `cases.caseCarbonMass / cases.caseYield${capitalize(settings.quantity)}`,
                target: "cases"
            }];
        },

        edgeStatisticsPath: (settings) => {
            return { sum: "kpis.carbonEmissions", mean: `kpis.carbonPerYield${capitalize(settings.quantity)}` };
        },

        // Definitions for the value stream section
        nodeStatisticsPath: {
            mean: "customKpis.carbonPerYieldMean.value",
            sum: "customKpis.carbonSum.value",
            median: "customKpis.carbonPerYield.statistics.median",
            variance: "customKpis.carbonPerYield.statistics",
        },

        nodeCustomKpis: (settings, session) => {
            if (settings.quantity === undefined)
                return undefined;
            // When the carbon mass is not defined, we calculate it based on the busy time.
            if (session?.project?.eventKeys?.carbonMass === undefined)
                return [{
                    id: "carbonPerYield",
                    definition: `0.002 * events.busyTime / events.yield${capitalize(settings.quantity)}`,
                    statistics: { aggs: [AggTypes.Sum, AggTypes.Mean, AggTypes.Median, AggTypes.Min, AggTypes.Max, AggTypes.P25, AggTypes.P75] },
                    target: "graph.nodes"
                }, {
                    id: "carbonPerYieldMean",
                    definition: `0.002 * graph.nodes.busyTimeStatistics.sum / graph.nodes.yield${capitalize(settings.quantity)}Statistics.sum`,
                    target: "graph.nodes"
                }, {
                    id: "carbonSum",
                    definition: "0.002 * graph.nodes.busyTimeStatistics.sum",
                    target: "graph.nodes"
                }];
            // In all other cases we can get it from the carbon mass.
            return [{
                id: "carbonPerYield",
                definition: `events.carbonMass/ events.yield${capitalize(settings.quantity)}`,
                statistics: { aggs: [AggTypes.Sum, AggTypes.Mean, AggTypes.Median, AggTypes.Min, AggTypes.Max, AggTypes.P25, AggTypes.P75] },
                target: "graph.nodes"
            }, {
                id: "carbonPerYieldMean",
                definition: `graph.nodes.carbonMassStatistics.sum / graph.nodes.yield${capitalize(settings.quantity)}Statistics.sum`,
                target: "graph.nodes"
            }, {
                id: "carbonSum",
                definition: "graph.nodes.carbonMassStatistics.sum",
                target: "graph.nodes"
            }];
        },

        nodeOverTimeStatisticsPath: (settings) => {
            switch (settings.kpi.statistic) {
                case StatisticTypes.Mean:
                    return "customKpis.carbonPerYieldMean.value";
                case StatisticTypes.Median:
                    return "customKpis.carbonPerYield.statistics.median";
                case StatisticTypes.Sum:
                    return "customKpis.carbonSum.value";
                case StatisticTypes.Variance:
                    return "customKpis.carbonPerYield.statistics";
            }
        },

        nodeOverTimeCustomKpis: (session, settings) => {
            if (settings.quantity === undefined)
                return undefined;
            // When the carbon mass is not defined, we calculate it based on the busy time.
            if (session?.project?.eventKeys?.carbonMass === undefined)
                return [{
                    id: "carbonPerYield",
                    definition: `0.002 * events.busyTime / events.yield${capitalize(settings.quantity)}`,
                    statistics: { aggs: [AggTypes.Sum, AggTypes.Mean, AggTypes.Median, AggTypes.Min, AggTypes.Max, AggTypes.P25, AggTypes.P75] },
                    target: "graph.nodes"
                }, {
                    id: "carbonPerYieldMean",
                    definition: `0.002 * graph.nodes.busyTimeStatistics.sum / graph.nodes.yield${capitalize(settings.quantity)}Statistics.sum`,
                    target: "graph.nodes"
                }, {
                    id: "carbonSum",
                    definition: "0.002 * graph.nodes.busyTimeStatistics.sum",
                    target: "graph.nodes"
                }];
            // In all other cases we can get it from the carbon mass.
            return [{
                id: "carbonPerYield",
                definition: `events.carbonMass/ events.yield${capitalize(settings.quantity)}`,
                statistics: { aggs: [AggTypes.Sum, AggTypes.Mean, AggTypes.Median, AggTypes.Min, AggTypes.Max, AggTypes.P25, AggTypes.P75] },
                target: "graph.nodes"
            }, {
                id: "carbonPerYieldMean",
                definition: `graph.nodes.carbonMassStatistics.sum / graph.nodes.yield${capitalize(settings.quantity)}Statistics.sum`,
                target: "graph.nodes"
            }, {
                id: "carbonSum",
                definition: "graph.nodes.carbonMassStatistics.sum",
                target: "graph.nodes"
            }];
        },
    }
);

/**
 * The amount of energy that was consumed.
 * It can be aggregated for products and over time.
 */
kpiMap.set(
    KpiTypes.Energy,
    {
        label: "common.energyConsumption",
        unit: { sum: Formatter.units.energykWh, mean: Formatter.units.energyPerYield },
        isQuantityDependent: true,
        allowedQuantities: (session: SessionType) =>
            quantityHelper(session, QuantityType.CaseYield, QuantityType.Yield),
        requiredEventKeys: (session) => {
            if (isOniqEmployee(session) || session.project?.settings?.isDemoProject)
                return [];
            return ["electricityEnergy"];
        },
        apiParameters: { calculateTimeAndFreqStats: true, calculateBusyStats: true, calculateOutputStats: true, calculateEnergyStats: true },
        dataGapFilling: DataGapFilling.Zero,
        spotlightId: "Kpi-Energy",
        allowedStatistics: allStats,
        allowedComparisons: [KpiComparisons.None, KpiComparisons.BestProcesses],
        isLessBetter: true,
        productStatisticsPath: {
            sum: "customKpis.energy.statistics.sum",
            mean: "customKpis.energyPerYieldMean.value",
            median: "customKpis.energyPerYield.statistics.median",
            variance: "customKpis.energyPerYield.statistics"
        },
        productCustomKpis: (settings, session) => {
            const aggregation = settings.kpi.aggregation === AggregationTypes.Time ? "timeperiods" : "products";
            if (settings.quantity === undefined)
                return undefined;
            // When the electricity energy is not defined, we calculate it based on the busy time.
            if (session?.project?.eventKeys?.electricityEnergy === undefined)
                return [
                    {
                        id: "energy",
                        definition: "0.002 * 2.3 * cases.busyTimeStatistics.sum",
                        statistics: { aggs: [AggTypes.P25, AggTypes.P75, AggTypes.Mean, AggTypes.Median, AggTypes.Min, AggTypes.Max] },
                        target: aggregation,
                    }, {
                        id: "energyPerYield",
                        definition: `0.002 * 2.3 * cases.busyTimeStatistics.sum / cases.caseYield${capitalize(settings.quantity)}`,
                        statistics: { aggs: [AggTypes.P25, AggTypes.P75, AggTypes.Mean, AggTypes.Median, AggTypes.Min, AggTypes.Max] },
                        target: aggregation,
                    }, {
                        id: "energyPerYieldMean",
                        definition: `0.002 * 2.3 * ${aggregation}.caseBusyTimeStatistics.sum / ${aggregation}.caseYield${capitalize(settings.quantity)}Statistics.sum`,
                        target: aggregation,
                    }];
            // In all other cases we can get it from the electricity energy.
            return [{
                id: "energy",
                definition: "cases.caseElectricityEnergy",
                statistics: { aggs: [AggTypes.P25, AggTypes.P75, AggTypes.Mean, AggTypes.Median, AggTypes.Min, AggTypes.Max] },
                target: aggregation,
            }, {
                id: "energyPerYield",
                definition: `cases.caseElectricityEnergy / cases.caseYield${capitalize(settings.quantity)}`,
                statistics: { aggs: [AggTypes.P25, AggTypes.P75, AggTypes.Mean, AggTypes.Median, AggTypes.Min, AggTypes.Max] },
                target: aggregation,
            }, {
                id: "energyPerYieldMean",
                definition: `${aggregation}.caseElectricityEnergyStatistics.sum / ${aggregation}.caseYield${capitalize(settings.quantity)}Statistics.sum`,
                target: aggregation,
            }];
        },
        caseStatisticsPath: "customKpis.energy.value",
        caseCustomKpis: (settings, session) => {
            // When the electricity energy is not defined, we calculate it based on the busy time.
            if (session?.project?.eventKeys?.electricityEnergy === undefined)
                return [{
                    id: "energy",
                    definition: "0.002 * 2.3 * cases.busyTimeStatistics.sum",
                    target: "cases"
                }, {
                    id: "energyPerYield",
                    definition: `0.002 * 2.3 * cases.busyTimeStatistics.sum / cases.caseYield${capitalize(settings.quantity)}`,
                    target: "cases"
                }];
            // In all other cases we can get it from the electricity energy.
            return [{
                id: "energy",
                definition: "cases.caseElectricityEnergy",
                target: "cases"
            }, {
                id: "energyPerYield",
                definition: `cases.caseElectricityEnergy / cases.caseYield${capitalize(settings.quantity)}`,
                target: "cases"
            }];
        },

        edgeStatisticsPath: (settings) => {
            return { sum: "kpis.energyConsumption", mean: `kpis.energyPerYield${capitalize(settings.quantity)}` };
        },

        // Definitions for the value stream section
        nodeStatisticsPath: {
            mean: "customKpis.energyPerYieldMean.value",
            sum: "customKpis.energySum.value",
            median: "customKpis.energyPerYield.statistics.median",
            variance: "customKpis.energyPerYield.statistics",
        },

        nodeOverTimeStatisticsPath: (settings) => {
            return {
                [StatisticTypes.Mean]: "customKpis.energyPerYieldMean.value",
                [StatisticTypes.Sum]: "customKpis.energySum.value",
                [StatisticTypes.Median]: "customKpis.energyPerYield.statistics.median",
                [StatisticTypes.Variance]: ""
            }[settings.kpi.statistic];
        },

        nodeOverTimeCustomKpis(session, settings) {
            if (settings.quantity === undefined)
                return undefined;
            // When the carbon mass is not defined, we calculate it based on the busy time.
            if (session?.project?.eventKeys?.carbonMass === undefined)
                return [{
                    id: "energyPerYieldMean",
                    definition: `0.002 * 2.3 * graph.nodes.busyTimeStatistics.sum / graph.nodes.yield${capitalize(settings.quantity)}Statistics.sum`,
                    target: "graph.nodes"
                }, {
                    id: "energyPerYield",
                    definition: `0.002 * 2.3 * events.busyTime / events.yield${capitalize(settings.quantity)}`,
                    statistics: { aggs: [AggTypes.Sum, AggTypes.Mean, AggTypes.Median, AggTypes.Min, AggTypes.Max, AggTypes.P25, AggTypes.P75] },
                    target: "graph.nodes"
                }, {
                    id: "energySum",
                    definition: "0.002 * 2.3 * graph.nodes.busyTimeStatistics.sum",
                    target: "graph.nodes"
                }];
            // In all other cases we can get it from the electricity energy.
            return [{
                id: "energyPerYieldMean",
                definition: `graph.nodes.electricityEnergyStatistics.sum / graph.nodes.yield${capitalize(settings.quantity)}Statistics.sum`,
                target: "graph.nodes"
            }, {
                id: "energyPerYield",
                definition: `events.electricityEnergy / events.yield${capitalize(settings.quantity)}`,
                statistics: { aggs: [AggTypes.Sum, AggTypes.Mean, AggTypes.Median, AggTypes.Min, AggTypes.Max, AggTypes.P25, AggTypes.P75] },
                target: "graph.nodes"
            }, {
                id: "energySum",
                definition: "graph.nodes.electricityEnergyStatistics.sum",
                target: "graph.nodes"
            }];
        },

        nodeCustomKpis: (settings, session) => {
            if (settings.quantity === undefined)
                return undefined;
            // When the carbon mass is not defined, we calculate it based on the busy time.
            if (session?.project?.eventKeys?.carbonMass === undefined)
                return [{
                    id: "energyPerYieldMean",
                    definition: `0.002 * 2.3 * graph.nodes.busyTimeStatistics.sum / graph.nodes.yield${capitalize(settings.quantity)}Statistics.sum`,
                    target: "graph.nodes"
                }, {
                    id: "energyPerYield",
                    definition: `0.002 * 2.3 * events.busyTime / events.yield${capitalize(settings.quantity)}`,
                    statistics: { aggs: [AggTypes.Sum, AggTypes.Mean, AggTypes.Median, AggTypes.Min, AggTypes.Max, AggTypes.P25, AggTypes.P75] },
                    target: "graph.nodes"
                }, {
                    id: "energySum",
                    definition: "0.002 * 2.3 * graph.nodes.busyTimeStatistics.sum",
                    target: "graph.nodes"
                }];
            // In all other cases we can get it from the electricity energy.
            return [{
                id: "energyPerYieldMean",
                definition: `graph.nodes.electricityEnergyStatistics.sum / graph.nodes.yield${capitalize(settings.quantity)}Statistics.sum`,
                target: "graph.nodes"
            }, {
                id: "energyPerYield",
                definition: `events.electricityEnergy / events.yield${capitalize(settings.quantity)}`,
                statistics: { aggs: [AggTypes.Sum, AggTypes.Mean, AggTypes.Median, AggTypes.Min, AggTypes.Max, AggTypes.P25, AggTypes.P75] },
                target: "graph.nodes"
            }, {
                id: "energySum",
                definition: "graph.nodes.electricityEnergyStatistics.sum",
                target: "graph.nodes"
            }];
        },
    }
);

/**
 * The replenishment lead time is the average time it takes to reorder a component.
 * It is the sum of the throughput time of the component and the average production interval.
 *
 * KPI ID 3111
 */
kpiMap.set(
    KpiTypes.ReplenishmentLeadTime,
    {
        label: "common.replenishmentLeadTime",
        unit: Formatter.units.durationShort,
        isQuantityDependent: false,
        allowedQuantities: noAllowedKpiQuantities,
        apiParameters: { calculateTimeAndFreqStats: true },
        spotlightId: "Kpi-ReplenishmentLeadTime",
        allowedStatistics: [StatisticTypes.Mean],
        allowedComparisons: [KpiComparisons.None],
        isLessBetter: true,
        productCustomKpis: [
            {
                id: "replenishmentLeadTimeMean",
                definition: "products.durationStatistics.mean + log.duration / products.count",
                target: "products",
            },
        ],
        productStatisticsPath: {
            mean: "customKpis.replenishmentLeadTimeMean.value",
        }
    }
);

/**
 * The production interval is the average time between two productions of the same product.
 */
kpiMap.set(
    KpiTypes.OrderInterval,
    {
        label: "common.orderInterval",
        unit: Formatter.units.durationShort,
        isQuantityDependent: false,
        allowedQuantities: noAllowedKpiQuantities,
        apiParameters: { calculateTimeAndFreqStats: true },
        spotlightId: "Kpi-OrderInterval",
        allowedStatistics: [StatisticTypes.Mean],
        allowedComparisons: [KpiComparisons.None],
        isLessBetter: true,
        productCustomKpis: [
            {
                id: "orderIntervalMean",
                definition: "log.duration / products.count",
                target: "products",
            },
        ],
        productStatisticsPath: {
            mean: "customKpis.orderIntervalMean.value",
        }
    }
);



/**
 * The component share is the share of a component(product) that is being used for the final product.
 *
 */
kpiMap.set(
    KpiTypes.ComponentShare,
    {
        label: "supplyChain.componentShare",
        unit: Formatter.units.percent,
        isQuantityDependent: true,
        allowedQuantities: (session: SessionType) =>
            quantityHelper(session, QuantityType.CaseYield, QuantityType.Yield),
        apiParameters: { calculateTimeAndFreqStats: true, calculateOutputStats: true, },
        spotlightId: "Kpi-ComponentShare",
        allowedStatistics: [StatisticTypes.Mean],
        allowedComparisons: [KpiComparisons.None],
        isLessBetter: true,
        productCustomKpis: (settings) => [
            {
                id: "componentShare",
                definition: `graph.nodes.component${capitalize(settings.quantity)}Statistics.sum / graph.nodes.caseYield${capitalize(settings.quantity)}Statistics.sum`,
                target: "graph.nodes",
            },
        ],
        productStatisticsPath: {
            mean: "customKpis.componentShare.value",
        }
    }
);

/**
 * The storage time is the time a product spends in the storage.
 */
kpiMap.set(
    KpiTypes.StorageTime,
    {
        ...kpiMap.get(KpiTypes.ThroughputTime)!, // copy all properties from throughput time
        label: "supplyChain.storageTime",
        spotlightId: "Kpi-StorageTime",
    }
);

// Alias for WorkInProgress (Stock WIP for nodes)
kpiMap.set(KpiTypes.WorkInProgressBeforeEventInventory, {
    ...kpiMap.get(KpiTypes.WorkInProcessInventory)!,
    label: "output.goodsInProcessBeforeEvent",
});

// Alias for WorkInProgress (Stock WIP for nodes)
kpiMap.set(KpiTypes.OrderBacklogBeforeEvent, {
    ...kpiMap.get(KpiTypes.OrderBacklog)!,
    label: "common.orderBacklogBeforeEvent",
});

kpiMap.set(KpiTypes.GoodQuantityTransport, {
    ...kpiMap.get(KpiTypes.GoodQuantity)!,
    label: "common.goodQuantityTransport",
    allowedComparisons: noPlanningForRoutings,
});

kpiMap.set(KpiTypes.ThroughputRateTransport, {
    ...kpiMap.get(KpiTypes.ThroughputRate)!,
    label: "common.throughputTransport",
    allowedComparisons: noPlanningForRoutings,
});

kpiMap.set(KpiTypes.FrequencyTransport, {
    ...kpiMap.get(KpiTypes.Frequency)!,
    label: "common.frequencyTransport",
});


/**
 * The function returns a KpiDefinition by calling the correct functions if some are supplied.
 * Requires the session context if you plan to use the allowedQuantities or requiredEventKeys
 * properties.
 * @returns
 */
export function getKpiDefinition(kpi: KpiTypes | undefined, context: KpiDefinitionContext): KpiDefinition | undefined {
    if (kpi === undefined)
        return undefined;
    const kpiSettings = kpiMap.get(kpi);
    if (kpiSettings) {
        const customerKpiDef = context?.session?.project?.settings?.customerKpis?.[kpi];

        return {
            ...kpiSettings,
            id: kpi,
            allowedComparisons: isFunction(kpiSettings?.allowedComparisons) ? kpiSettings?.allowedComparisons(context.session, context.settings) : kpiSettings?.allowedComparisons as KpiComparisons[],
            unit: isFunction(kpiSettings?.unit) ? kpiSettings?.unit(context.settings) as UnitMetadata : kpiSettings.unit as UnitMetadata,
            requiredEventKeys: isFunction(kpiSettings?.requiredEventKeys) ? kpiSettings?.requiredEventKeys(context.session, context.settings) : kpiSettings?.requiredEventKeys as string[],
            caseStatisticsPath: transformCustomerKpiString(customerKpiDef?.caseStatisticsPath) ?? (isFunction(kpiSettings?.caseStatisticsPath) ? kpiSettings?.caseStatisticsPath(context.settings) : kpiSettings?.caseStatisticsPath) as string,
            productStatisticsPath: transformCustomerPath(customerKpiDef?.productStatisticsPath) ?? (isFunction(kpiSettings?.productStatisticsPath) ? kpiSettings?.productStatisticsPath(context.settings) : kpiSettings?.productStatisticsPath as string),
            logStatisticsPath: transformCustomerPath(customerKpiDef?.logStatisticsPath) ?? (isFunction(kpiSettings?.logStatisticsPath) ? kpiSettings.logStatisticsPath!(context.settings) : kpiSettings?.logStatisticsPath as string),
            productCustomKpis: transformCustomerKpis(customerKpiDef?.productCustomKpis, isFunction(kpiSettings?.productCustomKpis) ? kpiSettings?.productCustomKpis(context.settings, context.session) : kpiSettings?.productCustomKpis as CustomKpi[]),
            eventOverTimeStatisticsPath: transformCustomerPath(customerKpiDef?.eventOverTimeStatisticsPath) ?? (isFunction(kpiSettings?.eventOverTimeStatisticsPath) ? kpiSettings?.eventOverTimeStatisticsPath(context.session, context.settings) : kpiSettings?.eventOverTimeStatisticsPath as string),
            eventOverTimeCustomKpis: transformCustomerKpis(customerKpiDef?.eventOverTimeCustomKpis, isFunction(kpiSettings?.eventOverTimeCustomKpis) ? kpiSettings?.eventOverTimeCustomKpis(context.session, context.settings) : kpiSettings?.eventOverTimeCustomKpis as CustomKpi[]),
            caseCustomKpis: transformCustomerKpis(customerKpiDef?.caseCustomKpis, isFunction(kpiSettings?.caseCustomKpis) ? kpiSettings?.caseCustomKpis(context.settings, context.session) : kpiSettings?.caseCustomKpis as CustomKpi[]),
            nodeStatisticsPath: transformCustomerPath(customerKpiDef?.nodeStatisticsPath) ?? (isFunction(kpiSettings?.nodeStatisticsPath) ? kpiSettings?.nodeStatisticsPath(context.settings) : kpiSettings?.nodeStatisticsPath as string),
            nodeCustomKpis: transformCustomerKpis(customerKpiDef?.nodeCustomKpis, isFunction(kpiSettings?.nodeCustomKpis) ? kpiSettings?.nodeCustomKpis(context.settings, context.session) : kpiSettings?.nodeCustomKpis as CustomKpi[]),
            edgeCustomKpis: transformCustomerKpis(customerKpiDef?.edgeCustomKpis, isFunction(kpiSettings?.edgeCustomKpis) ? kpiSettings?.edgeCustomKpis(context.settings) : kpiSettings?.edgeCustomKpis as CustomKpi[]),
            edgeStatisticsPath: transformCustomerPath(customerKpiDef?.edgeStatisticsPath) ?? (isFunction(kpiSettings?.edgeStatisticsPath) ? kpiSettings?.edgeStatisticsPath(context.settings) : kpiSettings?.edgeStatisticsPath as string),
            allowedQuantities: isFunction(kpiSettings?.allowedQuantities) ? kpiSettings?.allowedQuantities(context.session, context.settings) : kpiSettings?.allowedQuantities as AllowedKpiQuantities,
            equipmentNodeStatsPath: transformCustomerPath(customerKpiDef?.equipmentNodeStatsPath) ?? (isFunction(kpiSettings?.equipmentNodeStatsPath) ? kpiSettings?.equipmentNodeStatsPath(context.settings) : kpiSettings?.equipmentNodeStatsPath as string),
            equipmentNodeCustomKpis: transformCustomerKpis(customerKpiDef?.equipmentNodeCustomKpis, isFunction(kpiSettings?.equipmentNodeCustomKpis) ? kpiSettings?.equipmentNodeCustomKpis(context.session, context.settings) : kpiSettings?.equipmentNodeCustomKpis as CustomKpi[]),
            edgeOverTimeCustomKpis: transformCustomerKpis(customerKpiDef?.edgeOverTimeCustomKpis, isFunction(kpiSettings?.edgeOverTimeCustomKpis) ? kpiSettings?.edgeOverTimeCustomKpis(context.session, context.settings) : kpiSettings?.edgeOverTimeCustomKpis as CustomKpi[]),
            edgeOverTimeStatisticsPath: transformCustomerPath(customerKpiDef?.edgeOverTimeStatisticsPath) ?? (isFunction(kpiSettings?.edgeOverTimeStatisticsPath) ? kpiSettings?.edgeOverTimeStatisticsPath(context.settings) : kpiSettings?.edgeOverTimeStatisticsPath as string),
            equipmentOverTimeStatisticsPath: transformCustomerPath(customerKpiDef?.equipmentOverTimeStatisticsPath) ?? (isFunction(kpiSettings?.equipmentOverTimeStatisticsPath) ? kpiSettings?.equipmentOverTimeStatisticsPath(context.settings) : kpiSettings?.equipmentOverTimeStatisticsPath as string),
            nodeOverTimeCustomKpis: transformCustomerKpis(customerKpiDef?.nodeOverTimeCustomKpis, isFunction(kpiSettings?.nodeOverTimeCustomKpis) ? kpiSettings?.nodeOverTimeCustomKpis(context.session, context.settings) : kpiSettings?.nodeOverTimeCustomKpis as CustomKpi[]),
            nodeOverTimeStatisticsPath: transformCustomerPath(customerKpiDef?.nodeOverTimeStatisticsPath) ?? (isFunction(kpiSettings?.nodeOverTimeStatisticsPath) ? kpiSettings?.nodeOverTimeStatisticsPath(context.settings) : kpiSettings?.nodeOverTimeStatisticsPath as string),
            isWipIncluded: isFunction(kpiSettings?.isWipIncluded) ? !!kpiSettings?.isWipIncluded(context.session) : !!kpiSettings?.isWipIncluded,
        };
    }

    function transformCustomerKpis(customerKpis: CustomKpi[] | undefined, defaultKpis: CustomKpi[] | undefined): CustomKpi[] | undefined {
        if (customerKpis === undefined || context?.session === undefined || context?.settings === undefined)
            return defaultKpis;

        const kpis = [...(customerKpis ?? defaultKpis ?? [])];
        const ids = uniq(kpis.map(kpi => kpi.id));

        const result = ids.map(id => {
            const customerKpi = customerKpis?.find(kpi => kpi.id === id);
            if (customerKpi)
                return {
                    ...customerKpi,
                    definition: transformCustomerKpiString(customerKpi.definition),
                    target: transformCustomerKpiString(customerKpi.target),
                } as CustomKpi;

            return defaultKpis?.find(kpi => kpi.id === id);
        }).filter(k => k !== undefined) as CustomKpi[];

        return (result.length === 0) ? undefined : result;
    }

    /**
     * This function replaces placeholders used in customer KPIs with the actual values.
     */
    function transformCustomerPath(prop: string | undefined | PathDefinitions): PathDefinitions | string | undefined {
        if (prop === undefined || context?.session === undefined || context?.settings === undefined)
            return undefined;

        if (isObject(prop)) {
            return {
                mean: transformCustomerKpiString(prop.mean),
                median: transformCustomerKpiString(prop.median),
                variance: transformCustomerKpiString(prop.variance),
                sum: transformCustomerKpiString(prop.sum),
            } as PathDefinitions;
        }

        return transformCustomerKpiString(prop);
    }

    function transformCustomerKpiString(prop: string | undefined): string | undefined {
        if (prop === undefined || context?.session === undefined || context?.settings === undefined)
            return undefined;

        return prop.replaceAll("$quantity", context.settings.quantity ?? "").
            replaceAll("$Quantity", capitalizeFirst(context.settings.quantity) ?? "").
            replaceAll("$aggregation", mapAggregationToTarget(context.settings.kpi.aggregation) ?? "").
            replaceAll("$Aggregation", capitalizeFirst(mapAggregationToTarget(context.settings.kpi.aggregation)) ?? "").
            replaceAll("$statistic", context.settings.kpi.statistic.toString() ?? "").
            replaceAll("$Statistic", capitalizeFirst(context.settings.kpi.statistic.toString()) ?? "").
            replaceAll("$selectedKpi", kpi?.toString() ?? "").
            replaceAll("$SelectedKpi", capitalizeFirst(kpi?.toString() ?? "") ?? "");
    }
}

function mapAggregationToTarget(aggregation: AggregationTypes): "products" | "cases" | "timeperiods" {
    switch (aggregation) {
        case AggregationTypes.Product:
            return "products";
        case AggregationTypes.Case:
            return "cases";
        case AggregationTypes.Time:
            return "timeperiods";
    }
}

/**
 * This returns a short label for the statistic, i.e. a symbol when appropriate.
 */
export function getStatisticSymbol(statistic: StatisticTypes) {

    switch (statistic) {
        case StatisticTypes.Mean:
            return i18n.t("common.statistics.shortMean");
        case StatisticTypes.Median:
            return i18n.t("common.statistics.median");
        case StatisticTypes.Sum:
            return i18n.t("common.statistics.shortSum");
        case StatisticTypes.Variance:
            return i18n.t("common.statistics.variance");
    }

}

/**
 * Returns the label for the statistic.
 */
export function getStatisticLabel(statistic: StatisticTypes) {

    return i18n.t(`common.statistics.${statistic}`);
}

/**
 * Returns the unit for settings.quantity. Syntactic sugar that calls into getQuantityUnit.
 */
function getQuantityUnitFromSettings(settings: SettingsType): UnitMetadata {

    return getQuantityUnit(settings.quantity);
}

/**
 * Returns the unit for quantity
 */
function getQuantityUnit(quantity: BaseQuantityType | undefined): UnitMetadata {

    switch (quantity) {
        case "count":
            return Formatter.units.count as UnitMetadata;
        case "length":
            return Formatter.units.metricLength as UnitMetadata;
        case "mass":
            return Formatter.units.weight as UnitMetadata;
        default:
            return Formatter.units.count as UnitMetadata;
    }
}

/**
 * Returns the unit for quantity per time
 * @param settings
 * @returns
 */
function getQuantityFlowUnitFromSettings(settings: SettingsType): UnitMetadata {

    return getQuantityFlowUnit(settings.quantity);
}

function getQuantityFlowUnit(quantity: BaseQuantityType | undefined): UnitMetadata {

    switch (quantity) {
        case "count":
            return Formatter.units.countFlow as UnitMetadata;
        case "length":
            return Formatter.units.speed as UnitMetadata;
        case "mass":
            return Formatter.units.massFlow as UnitMetadata;
        default:
            return Formatter.units.countFlow as UnitMetadata;
    }
}

function getProductCustomKpiForTimeType(timeType: TimeTypes): (settings: SettingsType) => CustomKpi[] | undefined {

    return (settings) => {
        const aggregation = settings.kpi.aggregation === AggregationTypes.Time ? "timeperiods" : "products";
        if (settings.quantity === undefined)
            return undefined;
        return [{
            id: `${timeType}TimePerCaseOutput${capitalize(settings.quantity)}`,
            definition: `cases.${timeType}TimeStatistics.sum / cases.caseOutput${capitalize(settings.quantity)}`,
            statistics: { aggs: [AggTypes.P25, AggTypes.P75, AggTypes.Mean, AggTypes.Median, AggTypes.Min, AggTypes.Max] },
            target: aggregation,
        }, {
            id: `${timeType}TimePerCaseOutput${capitalize(settings.quantity)}Mean`,
            definition: `${aggregation}.case${capitalizeFirst(timeType)}TimeStatistics.sum / ${aggregation}.caseOutput${capitalize(settings.quantity)}Statistics.sum`,
            target: aggregation,
        }];
    };
}

function getCaseCustomKpiForTimeType(timeType: TimeTypes): (settings: SettingsType) => CustomKpi[] | undefined {
    return (settings) => {
        if (settings.quantity === undefined)
            return undefined;
        return [{
            id: `${timeType}TimePerCaseOutput${capitalize(settings.quantity)}`,
            definition: `cases.${timeType}TimeStatistics.sum / cases.caseOutput${capitalize(settings.quantity)}`,
            target: "cases",
        }];
    };
}

function getNodeOverTimeCustomKpisForTimeType(timeType: TimeTypes, divideByMachineNumber = false): (session: SessionType, settings: SettingsType) => CustomKpi[] | undefined {
    return (_session, settings) => {
        if (settings.quantity === undefined)
            return undefined;
        if (groupingKeysPassCompatibleGroups.includes(settings.groupingKey))
            return [{
                id: `${timeType}TimePerOutput` + (divideByMachineNumber ? "OverMachines" : ""),
                definition: `nodes.timeperiods.${timeType}TimeStatistics.sum / nodes.timeperiods.output${capitalize(settings.quantity)}Statistics.sum` + (divideByMachineNumber ? " / graph.nodes.activityValues.machine.nUnique" : ""),
                target: "nodes.timeperiods"
            }, {
                id: `${timeType}TimePerOutputStatistics`,
                definition: `nodes.timeperiods.${timeType}Time / nodes.timeperiods.output${capitalize(settings.quantity)}`,
                statistics: { aggs: [AggTypes.Median] },
                target: "nodes.timeperiods"
            }];
        // The none groups are special because the nodes are split up and times are stored in the timeStatistics (except for pass changes).
        if (groupingKeysNoneGroups.includes(settings.groupingKey))
            return [
                {
                    id: "relativeConfirmationTime",
                    definition: `graph.nodes.timeStatistics.sum / graph.nodes.output${capitalize(settings.quantity)}Statistics.sum`,
                    target: "nodes.timeperiods"
                },
            ];
    };
}

function getEventOverTimeCustomKpisForTimeType(timeType: TimeTypes, isEquipment?: boolean): (session: SessionType, settings: SettingsType) => CustomKpi[] | undefined {
    return (session, settings) => {
        const aggregation = settings.kpi.aggregation === AggregationTypes.Time ?
            (isEquipment ? "equipment" : "timeperiods") :
            "products";

        const isScrapAvailable = session.project?.eventKeys?.[`scrap${capitalize(settings.quantity)}`] !== undefined;
        const isYieldAvailable = session.project?.eventKeys?.[`yield${capitalize(settings.quantity)}`] !== undefined;

        const eventString = isEquipment ? "equipment" : "events";

        const statParts: string[] = [];
        const meanParts: string[] = [];
        if (isScrapAvailable) {
            statParts.push(`${eventString}.scrap${capitalize(settings.quantity)}`);
            meanParts.push(`${aggregation}.scrap${capitalize(settings.quantity)}Statistics.sum`);
        }
        if (isYieldAvailable) {
            statParts.push(`${eventString}.yield${capitalize(settings.quantity)}`);
            meanParts.push(`${aggregation}.yield${capitalize(settings.quantity)}Statistics.sum`);
        }

        if (statParts.length === 0)
            return [];

        if (settings.quantity === undefined)
            return undefined;
        return [{
            id: `${timeType}TimePerOutput${capitalize(settings.quantity)}`,
            definition: `${eventString}.${timeType}TimeStatistics.total / (${statParts.join(" + ")})`,
            statistics: { aggs: [AggTypes.P25, AggTypes.P75, AggTypes.Mean, AggTypes.Median, AggTypes.Min, AggTypes.Max] },
            target: aggregation,
        }, {
            id: `${timeType}TimePerOutput${capitalize(settings.quantity)}Mean`,
            definition: `${aggregation}.${capitalizeFirst(timeType)}TimeStatistics.total / (${meanParts.join(" + ")})`,
            target: aggregation,
        }];
    };
}


function getProductPathForTimeType(timeType: TimeTypes, disableSum = false): (settings: SettingsType) => PathDefinitions | undefined {
    return (settings) => {
        return {
            mean: settings.quantity === undefined ? undefined : `customKpis.${timeType}TimePerCaseOutput${capitalize(settings.quantity)}Mean.value`,
            median: settings.quantity === undefined ? undefined : `customKpis.${timeType}TimePerCaseOutput${capitalize(settings.quantity)}.statistics.median`,
            variance: settings.quantity === undefined ? undefined : `customKpis.${timeType}TimePerCaseOutput${capitalize(settings.quantity)}.statistics`,
            sum: disableSum ? undefined : `case${capitalizeFirst(timeType)}TimeStatistics.sum`,
        };
    };
}

function getEventOverTimePathForTimeType(timeType: TimeTypes): (session: SessionType, settings: SettingsType) => PathDefinitions | undefined {
    return (_, settings) => {
        return {
            mean: settings.quantity === undefined ? undefined : `customKpis.${timeType}TimePerOutput${capitalize(settings.quantity)}Mean.value`,
            sum: `${timeType}TimeStatistics.total`,
        };
    };
}


function getNodeCustomKpiForTimeType(timeType: TimeTypes, divideByMachineNumber = false): (settings: SettingsType) => CustomKpi[] | undefined {

    return (settings) => {
        if (settings.quantity === undefined)
            return undefined;
        if (groupingKeysPassCompatibleGroups.includes(settings.groupingKey))
            return [{
                id: `${timeType}TimePerOutput` + (divideByMachineNumber ? "OverMachines" : ""),
                definition: `graph.nodes.${timeType}TimeStatistics.total / graph.nodes.output${capitalize(settings.quantity)}Statistics.sum` + (divideByMachineNumber ? " / graph.nodes.activityValues.machine.nUnique" : ""),
                target: "graph.nodes"
            }, {
                id: `${timeType}TimePerOutputStatistics`,
                definition: `events.${timeType}Time / events.output${capitalize(settings.quantity)}`,
                statistics: { aggs: [AggTypes.Min, AggTypes.Max] },
                target: "graph.nodes"
            }];
        // The none groups are special because the nodes are split up and times are stored in the timeStatistics (except for pass changes).
        if (groupingKeysNoneGroups.includes(settings.groupingKey))
            return [
                {
                    id: "relativeConfirmationTime",
                    definition: `graph.nodes.timeStatistics.sum / graph.nodes.output${capitalize(settings.quantity)}Statistics.sum`,
                    target: "graph.nodes"
                },
            ];
    };
}

function getNodePathForTimeType(timeType: TimeTypes, disableSum = false, divideByMachineNumber = false): (settings: SettingsType) => PathDefinitions | undefined {
    return (settings) => {
        if (settings.quantity === undefined)
            return undefined;
        if (groupingKeysPassCompatibleGroups.includes(settings.groupingKey))
            return {
                mean: `customKpis.${timeType}TimePerOutput${divideByMachineNumber ? "OverMachines" : ""}.value`,
                sum: disableSum ? undefined : `${timeType}TimeStatistics.total`,
                variance: `customKpis.${timeType}TimePerOutputStatistics.statistics`,
            };
        if (groupingKeysNoneGroups.includes(settings.groupingKey))
            return {
                mean: "customKpis.relativeConfirmationTime.value",
                sum: disableSum ? undefined : "timeStatistics.sum",
            };
    };
}

function getEquipmentNodeStatsPath(kpiType: "interruption" | "failure" | "setup" | "production"): (settings: SettingsType) => PathDefinitions | undefined {
    return (settings) => {
        if (settings.quantity === undefined)
            return undefined;

        return {
            sum: `${kpiType}TimeStatistics.total`,
        };
    };
}

/**
 * Helper function that checks if settings.kpi.selectedKpi uses custom KPIs under the hood.
 */
export function hasProductCustomKpi(session: SessionType, settings: SettingsType): boolean {
    const kpi = getKpiDefinition(settings.kpi.selectedKpi, { session, settings });
    return !!kpi?.productCustomKpis;
}

/**
 * This function returns a list of KPIs that can be applied to the current project.
 * It checks that all the necessary columns required to compute a KPI are present.
 * @param kpis List of KPIs to check. Usually you pass a member of KpiPresets here.
 * @returns List of KPIs that can be applied to the current project.
 */
export function getAllowedKpis(session: SessionType, settings: SettingsType, kpis: KpiTypes[], isCaseStatistics = false, isPlanning = false) {
    return kpis.filter(kpi => {
        const kpiDefinition = getKpiDefinition(kpi, { settings, session });
        if (kpiDefinition === undefined)
            return false;
        // Check if the kpi requires planning data and if planning data is labelled at all.
        if (kpiDefinition.requiresPlanningData && !session.project?.uploadIdPlan)
            return false;

        let allowed = true;
        // We check for the required event keys.
        if (kpiDefinition.requiredEventKeys !== undefined)
            allowed = kpiDefinition.requiredEventKeys.every(eventKey => get(session.project, eventKey) !== undefined);

        // We check whether the kpi is quantity dependent and if so whether the quantity is allowed.
        if (kpiDefinition.isQuantityDependent && kpiDefinition.allowedQuantities !== undefined)
            allowed = allowed && kpiDefinition.allowedQuantities.actual[isCaseStatistics ? "case" : "node"]?.includes(settings.quantity ?? "count");
        // If planning data is requested we also need to check whether the quantity is allowed for planning.
        if (isPlanning)
            allowed = allowed && kpiDefinition.allowedQuantities.plan[isCaseStatistics ? "case" : "node"]?.includes(settings.quantity ?? "count");
        return allowed;
    });
}

/**
 * This function returns the path to the number or statistic for the statistic types.
 * @param kpiDefinition kpi definition to be used
 * @param statistic statistic type to be used
 * @returns path to the number or statistic
 */
export function getTimeperiodStatisticPath(kpiDefinition: KpiDefinition, statistic: StatisticTypes): string | undefined {
    if (isString(kpiDefinition.eventOverTimeStatisticsPath)) {
        if (statistic === StatisticTypes.Variance || statistic === StatisticTypes.Median)
            return undefined;
        else return kpiDefinition.eventOverTimeStatisticsPath + "." + statistic;
    }
    switch (statistic) {
        case StatisticTypes.Mean:
            return kpiDefinition?.eventOverTimeStatisticsPath?.mean;
        case StatisticTypes.Sum:
            return kpiDefinition?.eventOverTimeStatisticsPath?.sum;
    }
}

/**
 * This function returns the path to the number or statistic for the statistic types.
 * @param kpiDefinition kpi definition to be used
 * @param statistic statistic type to be used
 * @returns path to the number or statistic
 */
export function getProductStatisticPath(kpiDefinition: KpiDefinition, statistic: StatisticTypes, useLogPath = false): string | undefined {
    const pathDefinition = useLogPath && !!kpiDefinition.logStatisticsPath ? kpiDefinition.logStatisticsPath : kpiDefinition.productStatisticsPath;
    if (isString(pathDefinition)) {
        if (statistic === StatisticTypes.Variance)
            return pathDefinition;
        else return pathDefinition + "." + statistic;
    }
    switch (statistic) {
        case StatisticTypes.Mean:
            return pathDefinition?.mean;
        case StatisticTypes.Median:
            return pathDefinition?.median;
        case StatisticTypes.Variance:
            return pathDefinition?.variance;
        case StatisticTypes.Sum:
            return pathDefinition?.sum;
    }
}

/**
 * This function returns the number or statistic for the statistic type.
 */
export function getStatisticFromObject(object?: TimeperiodCaseStatisticsSchema | ProductCaseAggregationStatisticsSchema | CaseAggregationStatistics | TimeperiodCaseAggregationStatisticsSchema | ProductCaseAggregationStatisticsSchema | CaseAggregationStatistics | ProductCaseAggregationStatistics | ProductDeviationStatisticsSchema | undefined, kpiDefinition?: KpiDefinition, statistic?: StatisticTypes, aggregation?: AggregationTypes, useLogPath = false): number | Stats | undefined {
    if (!object || !kpiDefinition || !statistic)
        return;

    const isTimeEventKpi = [KpiTypes.BusyTime, KpiTypes.InterruptionTime, KpiTypes.FailureTime, KpiTypes.SetupTime, KpiTypes.ProductionTime, KpiTypes.ScrapQuantity,
        KpiTypes.OrganizationalLosses, KpiTypes.ProcessLosses, KpiTypes.QualityLosses, KpiTypes.TechnicalLosses].includes(kpiDefinition.id);

    const path = (isTimeEventKpi && aggregation === AggregationTypes.Time && !useLogPath) ? getTimeperiodStatisticPath(kpiDefinition, statistic) : getProductStatisticPath(kpiDefinition, statistic, useLogPath);
    return path ? get(object, path) : undefined;
}

/**
 * This function returns the main kpi for the statistic and definition from the settings.
 */
export function getMainProductValue(object: CaseAggregationStatistics | ProductCaseAggregationStatistics | ProductDeviationStatisticsSchema | undefined, settings: SettingsType, session: SessionType): number | undefined {
    const kpiDefinition = getKpiDefinition(settings.kpi.selectedKpi, { settings, session });
    return getStatisticFromObject(object, kpiDefinition, settings.kpi.statistic, settings.kpi.aggregation) as number;
}

/**
 * This functions returns the correct unit based on the statistic type.
 */

export function getUnit(unit?: UnitMetadata | { sum: UnitMetadata, mean: UnitMetadata }, statistic?: StatisticTypes): UnitMetadata | undefined {
    if (statistic === StatisticTypes.Sum)
        return get(unit, "sum") ?? unit as UnitMetadata;
    return get(unit, "mean") ?? unit as UnitMetadata;
}

/**
 * Build the string that points to the kpi spotlight id.
 * @param type
 * @param suffixes
 * @returns
 */
export function buildKpiSpotlightId(type: KpiTypes, suffixes: string[] = []) {
    return ["Kpi", type, ...suffixes].filter(p => p?.length > 0).map(s => capitalizeFirst(s)!).join("-");
}

export function getKpiSpotlightId(session: SessionType, kpiType: KpiTypes, aggregation: AggregationTypes) {
    const spotlightIdBase = buildKpiSpotlightId(kpiType);
    return getFirstExistingSpotlight(session, [`${spotlightIdBase}-${aggregation.toString()}`, spotlightIdBase]);
}

export function getKpiSpotlightIdNode(session: SessionType, kpiType: KpiTypes, suffixes?: string[]) {
    const spotlightIdBase = buildKpiSpotlightId(kpiType);
    const spotlightWithSuffix = buildKpiSpotlightId(kpiType, suffixes);
    return getFirstExistingSpotlight(session, [spotlightWithSuffix, spotlightIdBase]);
}


/**
 * Returns list of statistics allowed for the given kpi type and aggregation.
 * This should only be used for the case kpis.
 *
 * TODO: The KPI definitions now support functions that return the allowed statistics
 * depending on the settings. We should migrate this logic there one day.
 */
export function caseKpiControlsGetAllowedStatistics(session: SessionType, settings: SettingsType, kpiType: KpiTypes, aggregation: AggregationTypes) {
    const allKpiStatistics = getKpiDefinition(kpiType, { session, settings })?.allowedStatistics;
    switch (aggregation) {
        case AggregationTypes.Product:
            return allKpiStatistics;
        case AggregationTypes.Case:
            if (kpiType === KpiTypes.WorkInProcessInventory || kpiType === KpiTypes.WorkInProgressBeforeEventInventory)
                return [StatisticTypes.Mean];

            return [StatisticTypes.Sum];
        case AggregationTypes.Time: {
            // TODO: Remove this after the time chart is enabled also for variance
            const isTimeKpi = [KpiTypes.BusyTime, KpiTypes.InterruptionTime, KpiTypes.FailureTime, KpiTypes.SetupTime, KpiTypes.ProductionTime, KpiTypes.ScrapQuantity,
                KpiTypes.OrganizationalLosses, KpiTypes.ProcessLosses, KpiTypes.QualityLosses, KpiTypes.TechnicalLosses].includes(kpiType);
            return allKpiStatistics?.filter(s => s !== StatisticTypes.Variance && (!isTimeKpi || s !== StatisticTypes.Median) && (kpiType !== KpiTypes.ScrapQuantity || s !== StatisticTypes.Mean));
        }
    }
}

/**
 * Returns list of statistics allowed for the given kpi type and grouping key.
 * It should be used for graph based views.
 * @param kpiType
 * @param groupingkey
 * @returns
 */
export function graphKpiControlsGetAllowedStatistics(kpiType: KpiTypes, groupingkey: GroupingKeys, session: SessionType, settings: SettingsType) {
    const allKpiStatistics = getKpiDefinition(kpiType, { session, settings })?.allowedStatistics;
    const medianVarianceEnabledGroupingKeys = [
        GroupingKeys.Machine,
        GroupingKeys.MachineValueStream,
        GroupingKeys.MachineObjectType,
        GroupingKeys.MachineObjectTypeValueStream,
    ];
    if ([KpiTypes.CycleTime, KpiTypes.ThroughputRate, KpiTypes.ThroughputRateTransport].includes(kpiType) && !medianVarianceEnabledGroupingKeys.includes(groupingkey))
        return allKpiStatistics?.filter(s => s !== StatisticTypes.Median && s !== StatisticTypes.Variance);
    return allKpiStatistics;
}

/**
 * Returns the custom kpi parameters to be supplied to the products / supply chain endpoint.
 */
export function getCustomKpiParameters(kpiTypes: KpiTypes[], settings: SettingsType, session: SessionType, addAllTimeStats?: boolean) {
    const kpiDefinitions = kpiTypes.map(k => getKpiDefinition(k, { session, settings })).filter(k => k !== undefined) as KpiDefinition[];

    const customKpis = kpiDefinitions.map(k => k?.productCustomKpis).filter(k => k !== undefined).flat();
    const apiParameters = getApiParameters(kpiDefinitions, addAllTimeStats);

    // Make sure we only request each customKpi only once
    const convertedCustomKpis = [...new Set(customKpis.map(k => stringify(k)))];
    const uniqueCustomKpis = uniqBy(convertedCustomKpis.map(k => JSON.parse(k)), (k) => k.id);
    return {
        ...apiParameters,
        customKpis: uniqueCustomKpis,
    };
}

/**
 * Collects all api parameters from the kpi definitions.
 * @param kpiDefinitions
 * @param addAllTimeStats
 * @returns
 */
export function getApiParameters(kpiDefinitions?: KpiDefinition[], addAllTimeStats?: boolean) {

    if (!kpiDefinitions)
        return {};

    const allParameters = kpiDefinitions.map(k => k?.apiParameters).filter(k => k !== undefined);
    if (addAllTimeStats)
        allParameters.push({
            calculateBusyStats: true,
            calculateSetupStats: true,
            calculateFailureStats: true,
            calculateInterruptionStats: true,
        });
    return Object.assign({}, ...allParameters);
}

/**
 * Function that returns the value and unit for case statistics.
 */
export function getCaseKpiValueAndUnit(object: CaseAggregationStatistics | ProductCaseAggregationStatistics | ProductDeviationStatisticsSchema | undefined, kpi: KpiTypes, statistic: StatisticTypes, settings: SettingsType, session: SessionType) {
    const kpiDefinition = getKpiDefinition(kpi, { settings, session });
    if (kpiDefinition === undefined)
        return;
    const unitForStatistic = getUnit(kpiDefinition.unit, statistic);
    const valueForStatistic = getStatisticFromObject(object, kpiDefinition, statistic, settings.kpi.aggregation) as number;
    return { value: valueForStatistic, formattedValue: unitForStatistic?.formatter(valueForStatistic, { locale: session.numberFormatLocale }), label: kpiDefinition.label };
}

/**
 * Subtime components for the busy time.
 */
export const busyTimeSubtypes = [
    { type: KpiTypes.ProductionTime, label: "common.production" },
    { type: KpiTypes.SetupTime, label: "common.setup" },
    { type: KpiTypes.FailureTime, label: "common.failure" },
    { type: KpiTypes.InterruptionTime, label: "common.interruption" }
];

/**
 * Subtime components for the delay time.
 */
export const delayTimeSubtypes = [
    { type: KpiTypes.TechnicalLosses, label: "common.technicalLossesShort" },
    { type: KpiTypes.OrganizationalLosses, label: "common.organizationalLossesShort" },
    { type: KpiTypes.ProcessLosses, label: "common.processLossesShort" },
    { type: KpiTypes.QualityLosses, label: "common.qualityLossesShort" }
];

export function hasDelaySubtimes(session: SessionType) {
    return ["isOtherTechnicalLosses", "isUnplannedPreventiveMaintenance",
        "isCorrectiveMaintenance", "isNonActiveMaintenance", "isMaterialShortage", "isDeliveryProblem",
        "isAuthorizationShortage", "isEmployeeShortage", "isOtherOrganizationalLosses",
        "isProcessLosses", "isQualityLosses"].some(k => session.project?.eventKeys?.[k] !== undefined);
}

export function decideTimeperiodApi(session: SessionType, kpiDef: KpiDefinition | undefined, allowPlanning: boolean, quantity?: BaseQuantityType, isEquipmentStats?: boolean, isProductSection?:boolean) {
    const { hasPlanningLog, hasRoutings } = getPlanningState(session);

    if (!kpiDef)
        return undefined;

    if (isEquipmentStats)
        return TimeperiodApis.Equipment;

    if (kpiDef.timeperiodApi === TimeperiodApis.Event &&
        (session.project?.settings?.eventStatisticsType !== EventStatisticsTypes.Equipment || isProductSection))
        return TimeperiodApis.Event;

    if (kpiDef.timeperiodApi === TimeperiodApis.Event &&
        session.project?.settings?.eventStatisticsType === EventStatisticsTypes.Equipment)
        return TimeperiodApis.Equipment;

    if (kpiDef.timeperiodApi === TimeperiodApis.CaseDeviation && !hasPlanningLog)
        // This tile cannot be displayed. It requires planning data, but don't have that.
        return undefined;

    if (kpiDef.timeperiodApi === TimeperiodApis.CaseDeviation)
        return TimeperiodApis.CaseDeviation;

    if (!hasPlanningLog || !allowPlanning)
        // If we don't have planning data, it's also a simple case
        return TimeperiodApis.Case;

    if (!kpiDef.allowedComparisons?.includes(KpiComparisons.Planning))
        // planning isn't allowed for this tile
        return TimeperiodApis.Case;

    if (allowPlanning && kpiDef.timeperiodApi === TimeperiodApis.Case && !hasRoutings && hasPlanningLog)
        return TimeperiodApis.CaseDeviation;

    if (kpiDef.timeperiodApi !== undefined)
        return kpiDef.timeperiodApi;

    // If we have a quantity dependent tile, but the planning quantities don't support
    // the selected one, we have to fall back to actual only
    if (kpiDef.isQuantityDependent &&
        quantity !== undefined &&
        !kpiDef.allowedQuantities.plan.case.includes(quantity) &&
        kpiDef.allowedQuantities.actual.case.includes(quantity))
        return TimeperiodApis.Case;

    if (quantity !== undefined && kpiDef.isQuantityDependent) {
        // Check if the quantity is available for deviation API requests. That requires
        // the planning data to contain certain columns, and these may not have been assigned.
        if (!kpiDef.allowedQuantities.plan["case"].includes(quantity))
            // Desired quantity is not OK for deviation API requests, fall back to actual only
            return TimeperiodApis.Case;
    }

    // If we reach this point, we can use the deviation API
    return TimeperiodApis.CaseDeviation;
}

export function getFiltersForKpi(kpiDefinition: KpiDefinition | undefined, settings: SettingsType, session: SessionType) {
    const defaultFilters = settings.previewFilters ?? settings.filters;
    if (kpiDefinition === undefined || session.project?.eventKeys?.isWip === undefined)
        return defaultFilters;

    const wipExcludedFilter = buildAttributeFilter(session, session.project?.eventKeys?.isWip, "true", true);
    const wipFilter = kpiDefinition.isWipIncluded || wipExcludedFilter === undefined ? [] : [wipExcludedFilter];
    return [...defaultFilters].concat(wipFilter);
}

export function getFiltersForKpis(kpiDefinitions: KpiDefinition[], settings: SettingsType, session: SessionType) {
    const isWipIncludedValues = kpiDefinitions.map(kpi => kpi.isWipIncluded);
    const isWipIncludedUniqueValues = [...new Set(isWipIncludedValues)];
    if (isWipIncludedUniqueValues.length > 1) {
        throw new Error("isWipIncluded value is not the same for all kpiDefinitions. " + 
            "Please make sure that only kpiDefinitions are used in the same request that have the same definition regarding WIP");
    }
    return getFiltersForKpi(kpiDefinitions[0], settings, session);
}
